import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-component',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class AppRootComponent implements OnInit {
  public constructor(
    private titleService: Title,
    public router: Router
  ) {
  }
  ngOnInit() {
    this.titleService.setTitle(environment.portalName + ' Dealer Portal');
  }
  /** This method redirects to the dashboard page*/
  public redirect(): void {
    this.router.navigate(['/'], { queryParams: {}, skipLocationChange: false });
  }
}
