 <div [class.logo_landing]="!true" class="logo">
   <a [class.logo_img_landing]="!true" [class.logo-img-toyota]="false"
     [class.logo-img]="!false"></a>
   <!-- <div [class.header__content_landing]="!isLoggedIn" class="header__content">
     <div class="content__margin">
       <span *ngIf="isLoggedIn" class="dealer__name">{{'header.labels.greeting' | translate}}, {{dealerName}}</span>
       <span [class.actions_landing]="!isLoggedIn" class="header__actions">
         <div *ngIf="!isLoggedIn && authentication.isLoggedIn()" class="login">
           <span class="custom__title">{{'header.labels.timeout' | translate }}</span>
           <span>{{'header.labels.disclaimer' | translate }}</span>
         </div>
         <button mat-stroked-button *ngIf="isLoggedIn && domainMatcher.getDomain === 'com'" class="logout"
           (click)="logout()">{{'header.actions.sign-out' | translate}}</button>
       </span>
     </div>
     <app-language-selector [class.language_selector_landing]="!isLoggedIn" class="language-selector">
     </app-language-selector>
   </div> -->
 </div>
