import { environment } from '../../../environments/environment';
/**
* @class State model declaration for store User credentials
**/
export interface AuthState {
  token: string;
  name: string;
  email: string;
  password: string;
  guid: string;
  objectId: string;
  isLoggedIn: boolean;
  dealerCode: string;
  UID: string;
  role: string;
}
/**
* @class Initial State model for store User credentials
**/
export const initialAuthState: AuthState = {
  token: '',
  name: '',
  email: '',
  password: '',
  guid: '',
  objectId: '',
  isLoggedIn: false,
  dealerCode: '',
  UID: '',
  role: '',
};
