import { LOCALE_ID, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Portal } from './portal.module';
import { environment } from '../environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic([{ provide: LOCALE_ID, useValue: navigator.language }])
  .bootstrapModule(Portal, { providers: [{ provide: LOCALE_ID, useValue: navigator.language }] })
  .catch(err => console.log(err));
