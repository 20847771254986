/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "@angular-redux/store/lib/src/components/ng-redux";
import * as i6 from "../../common/redux/auth-store/auth-dispatcher";
import * as i7 from "../../common/services/languages/translation.service";
import * as i8 from "../../common/services/matcher/domain-matcher";
import * as i9 from "../../common/redux/customer/customer-dispatcher";
import * as i10 from "../../common/redux/steps-store/step-dispatcher";
import * as i11 from "../../common/services/redirect-service/redirect.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "logo"]], [[2, "logo_landing", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "a", [], [[2, "logo_img_landing", null], [2, "logo-img-toyota", null], [2, "logo-img", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = !true; _ck(_v, 0, 0, currVal_0); var currVal_1 = !true; var currVal_2 = false; var currVal_3 = !false; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-dealer", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.HeaderComponent, [i3.ActivatedRoute, i4.DatePipe, i5.NgRedux, i6.AuthDispatcher, i7.TranslationService, i8.DomainMatcher, i9.CustomerDispatcher, i10.StepDispatcher, i11.RedirectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header-dealer", i2.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
