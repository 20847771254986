import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})

export class TranslationService {
    /**
     * @property Use to set after the constructor the language of the page, this is the object of tha NGX API
     **/
    public customLanguage: TranslateService;

    /**
     * @constructor Establish the Inject value from angular core for the language
     * By default extablish the language to en(English)
     * Then attempt to set the language depending on the @param LOCALE_ID
     **/
    constructor(
        @Inject(LOCALE_ID) locale_id: string,
        translate: TranslateService
    ) {
        this.customLanguage = translate;
        this.customLanguage.setDefaultLang('en');
        this.customLanguage.use(locale_id);
    }

    /**
     * @function languageChange Return a event emmiter when the language change
     * @returns AObservable object that can be used to subscribe to the change of the language
     **/
    public languageChange() {
        return this.customLanguage.onLangChange;
    }

    /**
     * @function setLanguage Used to set the language for the app post the construction phase
     * @param {string} language Recive the name of the translation according to the json in the assets
     **/
    public set setLanguage(language: string) {
        this.customLanguage.use(language);
    }

    /**
     * @function getCustomTranslation Used to adquired a desiredtranslation from the JSON language
     * @param {string | string[]} translation Posible values are string with the respective map for the JSON or
     * an array of strings with the respective map for the values of the JSON
     * @returns An Observable with the response of the desired translation to NGX SERVICE
     **/
    public getCustomTranslation(translation: string | string[]) {
        return this.customLanguage.get(translation);
    }

    /**
     * @function getCurentLanguage Return the current language of the app
     * @returns A string with the value of the current language
     **/
    public getCurrentLanguage() {
        return this.customLanguage.currentLang;
    }
    /*
    * Open Link / Smooth Scroll to the element
    * */
    linkAction(e) {
        e.preventDefault();
        const tarea = e.target.getAttribute('href');
        if (tarea.indexOf('http://') === 0 || tarea.indexOf('https://') === 0) {
            // do something here
            window.open(tarea, '_blank');
        } else {
            const element = document.getElementById(tarea.replace('#', ''));
            element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }
}
