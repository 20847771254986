var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var VehicleActions = /** @class */ (function () {
    function VehicleActions() {
    }
    /**
     * @function setBrand sets the brand of the vehicle
     * @param {string} brand Value of the brand (T or L)
     **/
    VehicleActions.setBrand = function (brand) {
        var x_brand = (brand === 'TOY' || brand === 'TOYOTA' || brand === 'T') ? 'T' : 'L';
        return {
            type: VehicleActions.SET_BRAND,
            payload: { brand: x_brand }
        };
    };
    /**
     * @function setVin sets the brand of the vehicle
     * @param {string} vin Value of the vin (T or L)
     **/
    VehicleActions.setVin = function (vin) {
        return {
            type: VehicleActions.SET_VIN,
            payload: { vin: vin }
        };
    };
    /**
   * @function setVin sets the brand of the vehicle
   * @param {string} vin Value of the vin (T or L)
   **/
    VehicleActions.setVehicleDetails = function (vehicleDetails) {
        var x_brand = (vehicleDetails.brandCode === 'TOY' || vehicleDetails.brandCode === 'TOYOTA' || vehicleDetails.brandCode === 'T') ? 'T' : 'L';
        var storePayload = {
            vin: vehicleDetails.vin,
            brandCode: x_brand,
            asiCode: vehicleDetails.asiCode,
            imei: vehicleDetails.imei,
            region: vehicleDetails.region,
            generation: vehicleDetails.generation,
            nonCvtVehicle: vehicleDetails.nonCvtVehicle ? vehicleDetails.nonCvtVehicle :
                (!!vehicleDetails.imei &&
                    ((vehicleDetails.generation !== 'PRE17CY' && !!vehicleDetails.asiCode) || (vehicleDetails.generation === 'PRE17CY'))
                    ? false : true),
            stockPicReference: vehicleDetails.stockPicReference,
            color: {
                colorCode: vehicleDetails.color.colorCode,
                marketingColor: vehicleDetails.color.marketingColor
            },
            model: vehicleDetails.model,
            dcm: {
                hardwareType: vehicleDetails.dcm.hardwareType
            },
            capabilities: vehicleDetails.capabilities,
            wifi: vehicleDetails.capabilities.some(function (capability) { return (capability['name'] === 'Wifi' && capability['value'] === true); })
        };
        return {
            type: VehicleActions.SET_VEHICLE_DETAILS,
            payload: storePayload
        };
    };
    /**
     * @function setPreviewPayload sets the preview payload of the vehicle
     * @param {string} preview contains product details
     **/
    VehicleActions.setPreviewPayload = function (preview) {
        return {
            type: VehicleActions.SET_VEHICLE_DETAILS,
            payload: { preview: __assign({}, preview) }
        };
    };
    /**
     * @function setWaive sets the waive
     * @param {boolean} waive flag
     **/
    VehicleActions.setWaive = function (waive) {
        return {
            type: VehicleActions.SET_WAIVE,
            payload: { waive: waive }
        };
    };
    /**
     * @function setWifi sets the wifi
     * @param {boolean} wifi flag
     **/
    VehicleActions.setWifi = function (wifi) {
        return {
            type: VehicleActions.SET_WIFI,
            payload: { wifi: wifi }
        };
    };
    /**
     * @function setWifiStatus sets the wifi status
     * @param {boolean} wifiStatus status of the wifi
     **/
    VehicleActions.setWifiStatus = function (wifiStatus) {
        return {
            type: VehicleActions.SET_WIFI_STATUS,
            payload: { wifiStatus: wifiStatus }
        };
    };
    /**
     * @function resetVehicleStore sets the wifi
     **/
    VehicleActions.resetVehicleStore = function () {
        return {
            type: VehicleActions.RESET_VEHICLESTORE
        };
    };
    /**
     * @function setFilteredCapabilities sets the Filtered Capabilities
     * @param {string} filteredCapabilities capabilities of string type
     **/
    VehicleActions.setFilteredCapabilities = function (filteredCapabilities) {
        return {
            type: VehicleActions.SET_FILTERED_CAPABILITIES,
            payload: { filteredCapabilities: filteredCapabilities }
        };
    };
    /**
     * @function setVehicleTranslations sets the Filtered Capabilities
     * @param {string} vehicle vehicle translations
     **/
    VehicleActions.setVehicleTranslations = function (vehicle) {
        return {
            type: VehicleActions.SET_TRANSLATIONS,
            payload: vehicle
        };
    };
    /**
     * @function setActiveSubscriptions sets the active subscriptions of the vehicle
     * @param {string} subscriptions subscriptions of the vehicle
     **/
    VehicleActions.setActiveSubscriptions = function (subscriptions) {
        return {
            type: VehicleActions.SET_ACTIVE_SUBSCRIPTIONS,
            payload: { subscriptions: __assign({}, subscriptions) }
        };
    };
    /**
    * @property Declaration of the action to set the x-brand
    **/
    VehicleActions.SET_BRAND = 'SET_BRAND';
    /**
    * @property Declaration of the action to set the vin
    **/
    VehicleActions.SET_VIN = 'SET_VIN';
    /**
    * @property Declaration of the action to set the vin
    **/
    VehicleActions.SET_VEHICLE_DETAILS = 'SET_VEHICLE_DETAILS';
    /**
    * @property Declaration of the action to set the preview payload
    **/
    VehicleActions.SET_PREVIEW_PAYLOAD = 'SET_PREVIEW_PAYLOAD';
    /**
    * @property Declaration of the action to set waive
    **/
    VehicleActions.SET_WAIVE = 'SET_WAIVE';
    /**
    * @property Declaration of the action to set wifi
    **/
    VehicleActions.SET_WIFI = 'SET_WIFI';
    /**
    * @property Declaration of the action to set wifi status
    **/
    VehicleActions.SET_WIFI_STATUS = 'SET_WIFI_STATUS';
    /**
    * @property Declaration of the action to reset vehicle store
    **/
    VehicleActions.RESET_VEHICLESTORE = 'RESET_VEHICLESTORE';
    /**
    * @property Declaration of the action to set filtered capabilities
    **/
    VehicleActions.SET_FILTERED_CAPABILITIES = 'FILTERED_CAPABILITIES';
    /**
    * @property Declaration of the action to set filtered capabilities
    **/
    VehicleActions.SET_TRANSLATIONS = 'SET_TRANSLATIONS';
    /**
    * @property Declaration of the action to set the active subscriptions
    **/
    VehicleActions.SET_ACTIVE_SUBSCRIPTIONS = 'SET_ACTIVE_SUBSCRIPTIONS';
    return VehicleActions;
}());
export { VehicleActions };
