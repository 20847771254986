import { NgRedux } from '@angular-redux/store';
import { AuthActions } from './auth-actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular-redux/store/lib/src/components/ng-redux";
import * as i2 from "../../../store/dispatcher.module";
var AuthDispatcher = /** @class */ (function () {
    /**
    * @requires ngRedux Redux
    * @requires azureService MSAL azure library
    **/
    function AuthDispatcher(ngRedux) {
        this.ngRedux = ngRedux;
    }
    /**
    * @function setDealerDetails Store the user credentials
    * @param dealer code
    * @param email User email
    * @param Name User prefered name
    * @return {void}
    **/
    AuthDispatcher.prototype.setDealerDetails = function (user, type) {
        this.ngRedux.dispatch(AuthActions.setDealerDetails(user, type));
    };
    /**
    * @function setGuid Store the user GUID
    * @param guid User GUID
    * @return {void}
    **/
    AuthDispatcher.prototype.setGuid = function (guid) {
        this.ngRedux.dispatch(AuthActions.setGuid(guid));
    };
    /**
    * @function setObjectId Store the user objectId
    * @param objectId User ObjectId
    * @return {void}
    **/
    AuthDispatcher.prototype.setObjectId = function (objectId) {
        this.ngRedux.dispatch(AuthActions.setObjectId(objectId));
    };
    /**
    * @function setLogin Store the user login
    * @param isLoggedIn boolean whether login
    * @return {void}
    **/
    AuthDispatcher.prototype.setLogin = function (isLoggedIn) {
        this.ngRedux.dispatch(AuthActions.setLogin(isLoggedIn));
    };
    /**
    * @function setToken Store the token
    * @param token User token
    * @return {void}
    **/
    AuthDispatcher.prototype.setToken = function (token) {
        this.ngRedux.dispatch(AuthActions.setToken(token));
    };
    /**
    * @function Logout logout
    * @return {void}
    **/
    AuthDispatcher.prototype.logout = function () {
        this.ngRedux.dispatch(AuthActions.logout());
    };
    AuthDispatcher.ngInjectableDef = i0.defineInjectable({ factory: function AuthDispatcher_Factory() { return new AuthDispatcher(i0.inject(i1.NgRedux)); }, token: AuthDispatcher, providedIn: i2.DispatcherModule });
    return AuthDispatcher;
}());
export { AuthDispatcher };
