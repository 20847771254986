import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
import { environment } from '../../../environments/environment';
export type Action = FluxStandardAction<any, string>;

@Injectable()
export class AuthActions {
  /**
  * @property Declaration of the action to store the Token
  **/
  static readonly SET_TOKEN = 'SET_TOKEN';
  /**
  * @property Declaration of the action to set the dealer details
  **/
  static readonly SET_DEALER_DETAILS = 'SET_DEALER_DETAILS';
  /**
  * @property Declaration of the action to get the Token
  **/
  static readonly FETCH_TOKEN = 'FETCH_TOKEN';
  /**
  * @property Declaration of the action to store the GUID
  **/
  static readonly SET_GUID = 'SET_GUID';
    /**
  * @property Declaration of the action to store the objectId
  **/
 static readonly SET_OBJECT_ID = 'SET_OBJECT_ID';
  /**
  * @property Declaration of the action to store the azure login
  **/
  static readonly SET_LOGIN = 'SET_LOGIN';

  static setDealerDetails(user: object, type: string) {
    return {
      type: AuthActions.SET_DEALER_DETAILS,
      payload: type === 'tci' ?
        {
          email: '', name: user['given_name'], dealerCode: user['custom:DealerCode'], role: 'TCI_Enrolled',
          UID: (!!user['identities'] && user['identities'].length > 0) ? user['identities'][0]['userId'] : user['custom:DealerCode']
        } :
        { email: user['email'], name: user['family_name'], dealerCode: user['dealer_number'], role: user['memberof'], UID: user['UID'] }
    };
  }

  /**
  * @function setGuid Will dispatch the action to store the GUID
  * @param {string} Guid Value of the GUID for the user account
  **/
  static setGuid(guid: string) {
    return {
      type: AuthActions.SET_GUID,
      payload: { guid }
    };
  }

  /**
  * @function setObjectId Will dispatch the action to store the objectId
  * @param {string} objectId Value of the objectId for the user account
  **/
 static setObjectId(objectId: string) {
  return {
    type: AuthActions.SET_OBJECT_ID,
    payload: { objectId }
  };
}

  /**
  * @function setLogin Will dispatch the action to store the azure login
  * @param {string} isLoggedIn boolean to store login
  **/
  static setLogin(isLoggedIn: boolean) {
    return {
      type: AuthActions.SET_LOGIN,
      payload: { isLoggedIn: isLoggedIn }
    };
  }

  /**
  * @function setToken Will dispatch the action to store the GUID
  * @param {string} token Value of the GUID for the user account
  **/
  static setToken(token: string) {
    return {
      type: AuthActions.SET_TOKEN,
      payload: { token }
    };
  }
  /**
  * @function logout logout
  * @return {void}
  **/
  static logout() {
    return {
      type: AuthActions.SET_LOGIN,
      payload: { isLoggedIn: false }
    };
  }

}
