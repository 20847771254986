/**
 * Angular core components declaration
**/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/**
 * Subscription Components declaration
**/
import { VehiclesListComponent } from './vehicles-list/vehicles-list.component';
import { WaiveServicesComponent } from './waive-services/waive-services.component';
import { DataConsentComponent } from './data-consent/data-consent.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { RemoveConfirmationComponent } from './remove-confirmation/remove-confirmation.component';
import { SsaLinksComponent } from './ssa-links/ssa-links.component';

/**
* Subscription Modules declaration
**/
import { ACMModule } from '../account-management/acm.module';
import { CustomMaterialModule } from './material.components.module';
import { LanguageModule } from '../common/services/languages/language.module';

/**
 * Subscription Services declaration
**/
import { SubscriptionHeaderInterceptorService } from './common/header-interceptor/header-interceptor.service';
import { TranslationService } from '../common/services/languages/translation.service';
import { SpinnerOverlayModule } from '../common/components/spinner-overlay.module';
import { VehicleSubscriptionComponent } from './vehicle-subscription/vehicle-subscription.component';
import { VehicleSummaryComponent } from './vehicle-summary/vehicle-summary.component';
import { VehiclePackageComponent } from './vehicle-package/vehicle-package.component';
import { SubscriptionDetailsComponent } from './subscription-details/subscription-details.component';
import { VehicleAssociationComponent } from './vehicle-association/vehicle-association.component';
import { DownloadAppComponent } from './download-app/download-app.component';

@NgModule({
  imports: [
    BrowserModule,
    CustomMaterialModule,
    FormsModule,
    LanguageModule,
    ReactiveFormsModule,
    HttpClientModule,
    SpinnerOverlayModule,
    ACMModule
  ],
  declarations: [
    VehiclesListComponent,
    WaiveServicesComponent,
    DataConsentComponent,
    VehicleSubscriptionComponent,
    VehicleAssociationComponent,
    VehicleSummaryComponent,
    VehiclePackageComponent,
    ConfirmationComponent,
    SubscriptionDetailsComponent,
    RemoveConfirmationComponent,
    SsaLinksComponent,
    DownloadAppComponent
  ],
  exports: [
    VehiclesListComponent,
    VehicleSubscriptionComponent,
    VehicleAssociationComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SubscriptionHeaderInterceptorService, multi: true },
    DatePipe,
    TranslationService
  ]
})
export class SubscriptionModule {
  constructor() {
  }
}

