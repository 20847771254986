import { CanActivate, Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '../../store/state.model';
import { DomainMatcher } from '../services/matcher/domain-matcher';
import { Injectable } from '@angular/core';

/**
 * @export
 * @class FindvehicleGuardService
 * @implements {CanActivate}
 */
@Injectable()
export class GuidGuardService implements CanActivate {
  constructor(
    private _router: Router,
    public reduxState: NgRedux<AppState>,
    private domainMatcher: DomainMatcher) {
  }
  /**
   * @function canActivate will return true if guid exists
   * @returns {boolean}
   * @memberof GuidGuardService
   */
  public canActivate(): boolean {
    const guidExists = !!this.reduxState.getState().authStore.guid;
    if (guidExists) {
      return true;
    } else {
      this._router.navigate([`/${this.domainMatcher.getPortalState()}/search`, { outlets: { 'search_bar': null, 'content_bar': null } }],
        { skipLocationChange: true });
      return false;
    }
  }
}
