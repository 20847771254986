import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../../dealers/authentication/services/authentication.service';
import { AuthDispatcher } from '../../../common/redux/auth-store/auth-dispatcher';
import { LogService } from '../../../common/services/log-service/log-service.service';

/** Creates custom http calls get or post */
export class CustomHttpParams extends HttpParams {
  constructor(public spec: string) {
    super();
  }
}

/** Handles the Api calls with the body or header deired
 * allow the client perform custom logic on the error/succes payloads
 */
@Injectable()
export class ApiHandlerService extends HttpClient {
  /**
  * @property This property will host the value of the enviroment where needs to be done the API/Endpoint call
  **/
  private environment: string;

  /**
  * @property This property will determine the type of the api call
  **/
  private requestType: string;

  /**
  * @constructor Create the instance of extend clasess form httHandler
  * also initialize the value of the envrionment value
  **/
  constructor(
    private httpHandler: HttpHandler,
    private authService: AuthenticationService,
    private authDispatcher: AuthDispatcher,
    public logservice: LogService
  ) {
    super(httpHandler);
  }

  /**
  * @event intercept Intercept any request that is triggered by APIHandler and sets the common header of the ORCH layer
  * @returns {HTTPHandler} The response that is being intercepted so it can continue to the Network call
  **/
  getTypeParams() {
    return (!!this.requestType) ?
      new CustomHttpParams(this.requestType) : new CustomHttpParams('default');
  }
  /** This method posts a request to the passed url, body and options */
  postRequest(url: string, body: any | null, options?: any): Observable<any> {
    const headers: HttpHeaders = this.getHeaders(options);
    return super.post(`${this.environment}${url}`, Object.assign(body), {
      headers: headers,
      params: this.getTypeParams()
    }).map((res: any) => {
      return (res);
    }).catch(this.handleError.bind(this));
  }

  /** This method posts a request to the passed url, body and options */
  putRequest(url: string, body: any | null, options?: any): Observable<any> {
    const headers: HttpHeaders = this.getHeaders(options);
    return super.put(`${this.environment}${url}`, Object.assign(body), {
      headers: headers,
      params: this.getTypeParams()
    }).map((res: any) => {
      return (res);
    }).catch(this.handleError.bind(this));
  }

  /** This method gets a request to the passed url and options */
  getRequest(url: string, options?: any): Observable<any> {
    const headers: HttpHeaders = this.getHeaders(options);
    return super.get(`${this.environment}${url}`, {
      headers: headers,
      params: this.getTypeParams()
    }).map((res: any) => {
      return (res);
    }).catch(this.handleError.bind(this));
  }

  /** This method patches a request to the passed url, body and options */
  patchRequest(url: string, body: any | null, options?: any): Observable<any> {
    const headers: HttpHeaders = this.getHeaders(options);
    return super.patch(`${this.environment}${url}`, Object.assign(body), {
      headers: headers,
      params: this.getTypeParams()
    }).map((res: any) => {
      return (res);
    }).catch(this.handleError.bind(this));
  }

  /** This method adds the custom headers taken from the user input to the http request */
  getHeaders(data: { type: string, params: any }): HttpHeaders {
    this.authService.refreshToken('');
    this.environment = environment.apigw_url;
    this.requestType = (!!data && !!data.type) ? data.type : 'default';
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    if (!!data && data.type === 'acm-dealer' && !!data.params) {
      headers = (!!data.params.phone) ? headers.set('phone', data.params.phone) : headers.set('Email', data.params.email);
    } else if (!!data && data.type === 'acm-cu' && !!data.params) {
      headers = (!!data.params.generation) ? headers.set('x-generation', data.params.generation) : headers.set('x-generation', data.params.generation);
      headers = (!!data.params.vehiclebrands) ? headers.set('x-vehiclebrands', data.params.vehiclebrands) : headers.set('x-vehiclebrands', data.params.vehiclebrands);

    }
    return headers;
  }

  /** This method handles the errors of a http request*/
  handleError(err: HttpErrorResponse): Observable<ArrayBuffer> {
    if (this.isAuthenticationError(err)) {
      return Observable.throw({ errorCode: 'UNAUTHORIZED' });
    }
    const errorCode = this.checkErrorContent(err) &&
      err.error.status.messages.map(error => error.responseCode);
    const throwObj = (!!errorCode && errorCode.length) ? errorCode : [err.status];
    let errorResponse = { 'success': false, errorCode: throwObj[0] };
    if ((!!errorCode &&
      (errorCode.indexOf('OCPR-0011') > -1 || errorCode.indexOf('ORCH-0011') > -1))
      && this.requestType === 'acm-dealer') {
      errorResponse = Object.assign({ 'customerFound': false }, errorResponse);
    } else {
      this.logservice.logRequest(err);
    }
    return Observable.throw(errorResponse);
  }
  /** This method determines if it is an authentication error from the api gateway*/
  isAuthenticationError(err: HttpErrorResponse): boolean {
    if (this.checkErrorContent(err) && err.error.status.messages[0].detailedDescription.indexOf('expired') > 0) {
      this.authDispatcher.logout();
    }
    return (err.status === 0 && err.ok === false && err.statusText === 'Unknown Error') ||
      (err.status === 403 && this.checkErrorContent(err) && err.error.status.messages[0].responseCode === 'APIGW-403');
  }
  /** This checks the structure of the error if there is a status object*/
  checkErrorContent(err: HttpErrorResponse): boolean {
    return err.error && err.error.status && err.error.status.messages && err.error.status.messages.length;
  }
}
