import { PipeTransform } from '@angular/core';
import { regex } from '../regex.constants';
var PhonePipe = /** @class */ (function () {
    function PhonePipe() {
    }
    PhonePipe.prototype.transform = function (value) {
        return value.toString().match(regex.phonePatternWithChar) ? value : value.toString().replace(regex.phoneNumberPattern, '($1)$2-$3');
    };
    return PhonePipe;
}());
export { PhonePipe };
