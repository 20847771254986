export interface CustomerState {
  isValid: boolean;
  error_customer_not_found: boolean;
  searchValue: object;
  error_code: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: object;
  preferredLanguage: string;
  createAccountTimestamp: string;
  prefferedLangApiCall: boolean;
}
export const initialCustomerState = {
  isValid: false,
  error_customer_not_found: false,
  searchValue: null,
  error_code: 'n/a',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  address: {},
  preferredLanguage: '',
  createAccountTimestamp: '',
  prefferedLangApiCall: false
};
