var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpHandler, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../../dealers/authentication/services/authentication.service';
import { AuthDispatcher } from '../../../common/redux/auth-store/auth-dispatcher';
import { LogService } from '../../../common/services/log-service/log-service.service';
/** Creates custom http calls get or post */
var CustomHttpParams = /** @class */ (function (_super) {
    __extends(CustomHttpParams, _super);
    function CustomHttpParams(spec) {
        var _this = _super.call(this) || this;
        _this.spec = spec;
        return _this;
    }
    return CustomHttpParams;
}(HttpParams));
export { CustomHttpParams };
/** Handles the Api calls with the body or header deired
 * allow the client perform custom logic on the error/succes payloads
 */
var ApiHandlerService = /** @class */ (function (_super) {
    __extends(ApiHandlerService, _super);
    /**
    * @constructor Create the instance of extend clasess form httHandler
    * also initialize the value of the envrionment value
    **/
    function ApiHandlerService(httpHandler, authService, authDispatcher, logservice) {
        var _this = _super.call(this, httpHandler) || this;
        _this.httpHandler = httpHandler;
        _this.authService = authService;
        _this.authDispatcher = authDispatcher;
        _this.logservice = logservice;
        return _this;
    }
    /**
    * @event intercept Intercept any request that is triggered by APIHandler and sets the common header of the ORCH layer
    * @returns {HTTPHandler} The response that is being intercepted so it can continue to the Network call
    **/
    ApiHandlerService.prototype.getTypeParams = function () {
        return (!!this.requestType) ?
            new CustomHttpParams(this.requestType) : new CustomHttpParams('default');
    };
    /** This method posts a request to the passed url, body and options */
    ApiHandlerService.prototype.postRequest = function (url, body, options) {
        var headers = this.getHeaders(options);
        return _super.prototype.post.call(this, "" + this.environment + url, Object.assign(body), {
            headers: headers,
            params: this.getTypeParams()
        }).map(function (res) {
            return (res);
        }).catch(this.handleError.bind(this));
    };
    /** This method posts a request to the passed url, body and options */
    ApiHandlerService.prototype.putRequest = function (url, body, options) {
        var headers = this.getHeaders(options);
        return _super.prototype.put.call(this, "" + this.environment + url, Object.assign(body), {
            headers: headers,
            params: this.getTypeParams()
        }).map(function (res) {
            return (res);
        }).catch(this.handleError.bind(this));
    };
    /** This method gets a request to the passed url and options */
    ApiHandlerService.prototype.getRequest = function (url, options) {
        var headers = this.getHeaders(options);
        return _super.prototype.get.call(this, "" + this.environment + url, {
            headers: headers,
            params: this.getTypeParams()
        }).map(function (res) {
            return (res);
        }).catch(this.handleError.bind(this));
    };
    /** This method patches a request to the passed url, body and options */
    ApiHandlerService.prototype.patchRequest = function (url, body, options) {
        var headers = this.getHeaders(options);
        return _super.prototype.patch.call(this, "" + this.environment + url, Object.assign(body), {
            headers: headers,
            params: this.getTypeParams()
        }).map(function (res) {
            return (res);
        }).catch(this.handleError.bind(this));
    };
    /** This method adds the custom headers taken from the user input to the http request */
    ApiHandlerService.prototype.getHeaders = function (data) {
        this.authService.refreshToken('');
        this.environment = environment.apigw_url;
        this.requestType = (!!data && !!data.type) ? data.type : 'default';
        var headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        if (!!data && data.type === 'acm-dealer' && !!data.params) {
            headers = (!!data.params.phone) ? headers.set('phone', data.params.phone) : headers.set('Email', data.params.email);
        }
        else if (!!data && data.type === 'acm-cu' && !!data.params) {
            headers = (!!data.params.generation) ? headers.set('x-generation', data.params.generation) : headers.set('x-generation', data.params.generation);
            headers = (!!data.params.vehiclebrands) ? headers.set('x-vehiclebrands', data.params.vehiclebrands) : headers.set('x-vehiclebrands', data.params.vehiclebrands);
        }
        return headers;
    };
    /** This method handles the errors of a http request*/
    ApiHandlerService.prototype.handleError = function (err) {
        if (this.isAuthenticationError(err)) {
            return Observable.throw({ errorCode: 'UNAUTHORIZED' });
        }
        var errorCode = this.checkErrorContent(err) &&
            err.error.status.messages.map(function (error) { return error.responseCode; });
        var throwObj = (!!errorCode && errorCode.length) ? errorCode : [err.status];
        var errorResponse = { 'success': false, errorCode: throwObj[0] };
        if ((!!errorCode &&
            (errorCode.indexOf('OCPR-0011') > -1 || errorCode.indexOf('ORCH-0011') > -1))
            && this.requestType === 'acm-dealer') {
            errorResponse = Object.assign({ 'customerFound': false }, errorResponse);
        }
        else {
            this.logservice.logRequest(err);
        }
        return Observable.throw(errorResponse);
    };
    /** This method determines if it is an authentication error from the api gateway*/
    ApiHandlerService.prototype.isAuthenticationError = function (err) {
        if (this.checkErrorContent(err) && err.error.status.messages[0].detailedDescription.indexOf('expired') > 0) {
            this.authDispatcher.logout();
        }
        return (err.status === 0 && err.ok === false && err.statusText === 'Unknown Error') ||
            (err.status === 403 && this.checkErrorContent(err) && err.error.status.messages[0].responseCode === 'APIGW-403');
    };
    /** This checks the structure of the error if there is a status object*/
    ApiHandlerService.prototype.checkErrorContent = function (err) {
        return err.error && err.error.status && err.error.status.messages && err.error.status.messages.length;
    };
    return ApiHandlerService;
}(HttpClient));
export { ApiHandlerService };
