import { TranslationService } from '../../common/services/languages/translation.service';
import { DomainMatcher } from '../../common/services/matcher/domain-matcher';
import { AgentContactService } from '../../common/services/agent-contact/agent-contact.service';
import { MatDialogRef } from '@angular/material';
import { environment } from '../../environments/environment';
var WelcomeComponent = /** @class */ (function () {
    function WelcomeComponent(dialogRef, translate, domainMatcher, agentContactService) {
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.domainMatcher = domainMatcher;
        this.agentContactService = agentContactService;
    }
    /**
     * @function getCaAgentContact returns the ca agent contact translation
     * @returns {string} the Spattern corresponding to the agent contact
     */
    WelcomeComponent.prototype.getCaAgentContact = function () {
        return this.agentContactService.getCaAgentContact();
    };
    /**
     * @function getUsAgentContact returns the us agent contact translation
     * @returns {string} the Spattern corresponding to the agent contact
     */
    WelcomeComponent.prototype.getUsAgentContact = function (region) {
        return this.agentContactService.getUsAgentContact(region);
    };
    /**
     * @function getPhone returns the phone
     * @returns {string} return phone number
     */
    WelcomeComponent.prototype.getPhone = function (region) {
        return this.agentContactService.getPhone(region);
    };
    /**
       * @method close closes the dialog box
      **/
    WelcomeComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    WelcomeComponent.prototype.selectLanguage = function (event) {
        this.show = event;
    };
    /**
      * @function getPattern gets the translation pattern based on parameter - region based, portal brand based
      */
    WelcomeComponent.prototype.getPattern = function (join1, join2, parameter) {
        var joinParam = parameter === 'region' ? (this.domainMatcher.getDomain === 'ca' ? 'tci' : '') : environment.brand;
        return "" + join1 + (!!joinParam ? "-" + joinParam : '') + (!!join2 ? "." + join2 : '');
    };
    return WelcomeComponent;
}());
export { WelcomeComponent };
