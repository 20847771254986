<div>
  <div class="welcome__close">
    <app-language-selector page_state='welcome' (langSelected)="selectLanguage($event)" class="language-selector">
    </app-language-selector>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="welcome__wrapper">
    <span class="welcome__message">{{'welcome.sub-title'|translate}}</span>
    <span class="welcome__title">{{getPattern(getPattern('welcome.title','','brand'),'','region')|translate}}</span>
    <ng-container *ngTemplateOutlet="show ? content: content"></ng-container>
    <div *ngIf="domainMatcher.getDomain === 'ca'" class="custom__line">
      <span>{{'welcome.pre-message'| translate}}</span>
      <span>{{getPhone('tci')}}</span>
      <span>{{getCaAgentContact()| translate}}</span>
    </div>
    <div *ngIf="domainMatcher.getDomain !== 'ca'" class="custom__line">
      <span>{{'welcome.message'| translate}}</span>
      <div class="hint__agent">
        <span>{{'common-error.region.us'| translate}}: {{getUsAgentContact('us')| translate}} {{getPhone('us')}}</span>
        <span>{{'common-error.region.servco'| translate}}: {{getUsAgentContact('servco')| translate}}
          {{getPhone('servco')}}</span>
        <span>{{'common-error.region.tdpr'| translate}}: {{getUsAgentContact('tdpr')| translate}}
          {{getPhone('tdpr')}}</span>
      </div>
    </div>
    <div class="action__button">
      <button mat-stroked-button mat-dialog-close class=" button__fill">{{'welcome.action'|translate}}</button>
    </div>
  </div>

  <ng-template #content>
    <div>
      <span>{{'welcome.content'|translate}}</span>
      <ul class="welcome__list custom__line">
        <li>{{'welcome.content-point.1'|translate}}</li>
        <li>{{'welcome.content-point.2'|translate}}</li>
        <li>{{'welcome.content-point.3'|translate}}</li>
        <li>{{'welcome.content-point.4'|translate}}</li>
        <li>{{'welcome.content-point.5'|translate}}</li>
      </ul>
    </div>
  </ng-template>
  <ng-template #selector>
    <span>{{'welcome.content-point.selector'|translate}}</span>
    <app-language-selector page_state='welcome' (langSelected)="selectLanguage($event)" class="language-selector">
    </app-language-selector>
  </ng-template>
</div>
