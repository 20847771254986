/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./main.component";
import * as i4 from "@angular/platform-browser";
var styles_AppRootComponent = [i0.styles];
var RenderType_AppRootComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppRootComponent, data: {} });
export { RenderType_AppRootComponent as RenderType_AppRootComponent };
export function View_AppRootComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppRootComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-component", [], null, null, null, View_AppRootComponent_0, RenderType_AppRootComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppRootComponent, [i4.Title, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppRootComponentNgFactory = i1.ɵccf("app-component", i3.AppRootComponent, View_AppRootComponent_Host_0, {}, {}, []);
export { AppRootComponentNgFactory as AppRootComponentNgFactory };
