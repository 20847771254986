import { Injectable } from '@angular/core';
import { DomainMatcher } from '../matcher/domain-matcher';
import { environment } from '../../../environments/environment';
import { AppState } from '../../../store/state.model';
import { NgRedux } from '@angular-redux/store';

@Injectable({
  providedIn: 'root'
})
export class AgentContactService {

  constructor(
    private domainMatcher: DomainMatcher,
    public ngRedux: NgRedux<AppState>
  ) { }

  /**
 * @function getCaAgentContact returns the ca agent contact translation
 * @returns {string} the Spattern corresponding to the agent contact
 */
  public getCaAgentContact(): string {
    return environment.X_BRAND === 'T' ? 'welcome.post-message-toyota' : 'welcome.post-message-lexus';
  }
  /**
   * @function getUsAgentContact returns the us agent contact translation
   * @returns {string} the Spattern corresponding to the agent contact
   */
  public getUsAgentContact(region: string): string {
    return environment.X_BRAND === 'T' ? `common-error.hint.toyota-${region}-agent-contact` : `common-error.hint.lexus-${region}-agent-contact`;
  }
  /**
   * @function getAgentHint get agent hint
   * @returns {string} the pattern corresponding to the agent contact
   */
  public getAgentHint(): string {
    let hint;
    switch (this.ngRedux.getState().vehicleStore.region) {
      case 'CA': hint = this.getCaAgentContact();
        break;
      case 'HI': hint = this.getUsAgentContact('servco');
        break;
      case 'PR': hint = this.getUsAgentContact('tdpr');
        break;
      default: hint = this.getUsAgentContact('us');
        break;
    }
    return hint;
  }

  /**
   * @function getPhone returns the phone
   * @returns {string} return phone number
   */
  public getPhone(region: string): string {
    return environment.subscription[`${region}_agent_phone`];
  }
  public getPhoneHint(): string {
    let phone;
    switch (this.ngRedux.getState().vehicleStore.region) {
      case 'CA': phone = this.getPhone('tci');
        break;
      case 'HI': phone = this.getPhone('servco');
        break;
      case 'PR': phone = this.getPhone('tdpr');
        break;
      default: phone = this.getPhone('us');
        break;
    }
    return phone;
  }
}
