import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
export type Action = FluxStandardAction<any, string>;

@Injectable()
export class SubscriptionActions {
  /**
  * @property Declaration of the action to set subscription of packages and wifi state
  **/
  static readonly SET_SUB_STATE = 'SET_SUB_STATE';
  /**
  * @property Declaration of the action to set subscription of packages and wifi state
  **/
  static readonly SET_SUB_RES = 'SET_SUB_RES';
  /**
  * @property Declaration of the action to set subscription of packages and wifi state
  **/
  static readonly SET_ADD_VEHICLE_RES = 'SET_ADD_VEHICLE_RES';
  /**
  * @property Declaration of the action to set the skip package subscription
  **/
  static readonly SKIP_PACKAGE_SUB = 'SKIP_PACKAGE_SUB';
  /**
  * @property Declaration of the action to RESET
  **/
 static readonly RESET_SUBSCRIPTIONSTORE = 'RESET_SUBSCRIPTIONSTORE';
  /**
    * @function setSubscriptionResponse sets the subscription result for packages and wifi
    * @param {object} result result of the api call
    **/
  static setSubscriptionResponse(result: object, type: string) {
    return {
      type: SubscriptionActions.SET_SUB_RES,
      payload: { response: { ...result }, type: type }
    };
  }
  /**
   * @function setAddVehicleResponse sets the subscription result for packages and wifi
   * @param {object} result result of the api call
   **/
  static setAddVehicleResponse(result: object) {
    return {
      type: SubscriptionActions.SET_ADD_VEHICLE_RES,
      payload: { response: { ...result } }
    };
  }
  /**
   * @function setSubscriptionState sets the waive
   * @param {boolean} state flag
   **/
  static setSubscriptionState(state: boolean) {
    return {
      type: SubscriptionActions.SET_SUB_STATE,
      payload: { state }
    };
  }
  /**
   * @function resetSubscriptionStore sets the wifi
   **/
  static resetSubscriptionStore() {
    return {
      type: SubscriptionActions.RESET_SUBSCRIPTIONSTORE
    };
  }
}
