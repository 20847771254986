export const environment = {
  roles: ['DD_Enrolled', 'TCI_Enrolled', 'OT_CT_Enrolled'],
  us_url: 'oneportallexusdealer.stg.telematicsct.com',
  ca_url: 'oneportallexusdealer.stg.telematicsct.ca',
  privacyandleagal_url: 'http://oneportal.privacyandlegalterms.telematicsct.com',
  production: true,
  brand: 'lx',
  portalName: 'Lexus',
  portalTitle: 'Lexus Dealer Portal',
  X_BRAND: 'L',
  apigw_url: 'https://oneportalapi.stg.telematicsct.com',
  apigw_api_key: 'mF6JqRtb0k1OdCbcE87Z89ry3594qGEe1fLcChVq',
  us_config: {
    clientID: '885f434e-214b-43b2-ada6-abd991d98b60',
    tenantID: 'tmnab2cqa.onmicrosoft.com',
    policy: 'B2C_1A_OnePortal_QA_SignInPolicy',
    authority: `https://login.microsoftonline.com/`,
    redirectUri: 'http://localhost:4200/us',
    consentScopes: [
      'openid',
      'https://tmnab2cqa.onmicrosoft.com/oneportalqaapi/read'
    ],
    queryParams: 'p=B2C_1A_OnePortal_QA_SignInPolicy'
  },
  tci_config: {
    issuer: 'https://cognito-idp.us-east-2.amazonaws.com/us-east-2_xVjDUCI07',
    domain: 'tci-ctp.auth.us-east-2.amazoncognito.com',
    redirectUri: 'https://oneportallexusdealer.stg.telematicsct.ca/tci',
    clientId: 'd2rtgq9ite332o37cnh84hdv7',
    scope: 'openid profile',
    strictDiscoveryDocumentValidation: false,
    responseType: 'token',
    oidc: true,
    requestAccessToken: false,
    // tslint:disable-next-line:max-line-length
    jwks: { 'keys': [{ 'alg': 'RS256', 'e': 'AQAB', 'kid': 'ZbunQDaSXzBrxcGTLk7JWVPJQFlK04btPB5vW5eoQZA=', 'kty': 'RSA', 'n': 'prPcksZbUf7a_b4lRCzCtASG3fWzdcdSLgcMBGg8lyk4J3GNZFyeWKI1V3EDYZF49WatQsaejTNh17ovfTer6CMcMgfs29BVD5Z81GJpflxEzIQ5SfzR62cfETihGM_ZuCIf0psAWcvs_jlElLGvZo6M5UgGvruAkv6HkAtIjKA_lS94ZXBNeSS0FtrhNzusoqbxv140AP1Ya6zO_nDFMtouUorZzuqSMuKJYukOI5UPcmqwbwrTgLlDwbHYJNKHHrumXTiRxmLf3fdQhQ3_UiRp55k03n4xBfKTNJ4I9lm2xR8ox5NPck9Y1DcgGWmaYYFapGgRpRUFS1RFj0O9NQ', 'use': 'sig' }, { 'alg': 'RS256', 'e': 'AQAB', 'kid': 'j8G+fYKW9tN/SBkIVNrJO43IyZli96wK1Ch5qGWyAvQ=', 'kty': 'RSA', 'n': 'snDeahuA0UhbGBK5qIyHBG6Gv3Oa5dKDCnuVx3wJ6E2O9-dpZXvf0F6o_PYepiKJAsauF5PrYsrfzxVHKLmwQ1cb4tfR3nGtJfskwslOxJuzW1ctkDX7qY5yYtiZVuiuV0kwDksj8AR4AOPEScFLutKl9KCO2fyr7QnkjQBrcfkgsIPeNZAM04B0B2YUY5ApL9Q_E2O-XrPVmAn5dFBIcQulep9FAjNBxFi4nBQIbj5YwfAajzAH_Qgpc7s81uMdTAXF4EUZwSPQF3zol2QDGcPwo_ba3ldtp7Sm035v_5BmYs5ZPfkF0AI6UjZpbWS2aYJ3E2zKXT7bymreiXh5hQ', 'use': 'sig' }] }
  },
  acm: {
    X_CHANNEL_DEALER: 'DEALER',
    account_creation: {
      url: '/v1/account',
      default_body: {
        customerType: 'PERSON',
      }
    },
    customer_search: {
      url: '/v1/account'
    },
    com: {
      languages: [{
        value: 'en',
        description: 'English',
        name: 'English'
      }, {
        value: 'sp',
        description: 'Español',
        name: 'Spanish'
      }],
      en: {
        legal_terms: 'https://www.toyota.com/support/legal-terms',
        privacy_policy: 'https://www.toyota.com/support/privacy-rights'
      }
    },
    ca: {
      languages: [{
        value: 'en',
        description: 'English',
        name: 'English'
      },
      {
        value: 'fr',
        description: 'Français',
        name: 'French'
      }],
      en: {
        legal_terms: 'https://www.lexus.ca/lexus/en/legal',
        privacy_policy: 'https://www.lexus.ca/lexus/en/privacy#/lexus-privacy-policy'
      },
      fr: {
        legal_terms: 'https://www.lexus.ca/lexus/fr/legal',
        privacy_policy: 'https://www.lexus.ca/lexus/fr/privacy#/lexus-privacy-policy'
      }
    }
  },
  subscription: {
    preferredLanguage: {
      ca: ['English', 'French'],
      tdpr: ['English', 'Spanish']
    },
    us_agent_phone: '866-849-0924',
    servco_agent_phone: '888-845-1424',
    tdpr_agent_phone: '877-539-8777',
    tci_agent_phone: '(888) 598-1111',
    X_CHANNEL_cvs: 'CTP',
    createSource: 'DEALER',
    apis: {
      getSubscription: '/subscription/v1/subscription',
      details: '/v4/vehicle',
      subscription: '/subscription/v1/subscriptions',
      vehicleAssociation: '/v1/vehicle/guid',
      vinlist: '/v1/vehicle/vin',
      addVehicle: '/vehicle-association/v1/vehicle',
      cancelSubscription: '/subscription/v1/cancel',
      unLink: '/vehicle-association/v1/vehicle/sold',
      customerDetails: '/v1/account',
      preview: '/v1/subscriptions',
      wifi: '/v1/wifitrial',
      wifiStatus: '/v1/wifistatus',
      logurl: '/v1/log',
      outageBanner: '/v1/banner'
    }
  }
};
