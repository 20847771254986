var SpinnerRef = /** @class */ (function () {
    function SpinnerRef(overlayRef) {
        this.overlayRef = overlayRef;
    }
    SpinnerRef.prototype.close = function () {
        this.overlayRef.dispose();
    };
    return SpinnerRef;
}());
export { SpinnerRef };
