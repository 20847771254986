var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgRedux } from '@angular-redux/store';
import { environment } from '../../../environments/environment';
import { DomainMatcher } from '../matcher/domain-matcher';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular-redux/store/lib/src/components/ng-redux";
import * as i2 from "../matcher/domain-matcher";
import * as i3 from "@angular/common/http";
/** Creates custom http calls get or post */
var CustomHttpParams = /** @class */ (function (_super) {
    __extends(CustomHttpParams, _super);
    function CustomHttpParams(spec) {
        var _this = _super.call(this) || this;
        _this.spec = spec;
        return _this;
    }
    return CustomHttpParams;
}(HttpParams));
export { CustomHttpParams };
var LogService = /** @class */ (function () {
    function LogService(ngRedux, domainMatcher, http) {
        this.ngRedux = ngRedux;
        this.domainMatcher = domainMatcher;
        this.http = http;
        this.setDefaults();
    }
    /**
     * @function setDefaults sets defaults
     */
    LogService.prototype.setDefaults = function () {
        this.correlation_id = '';
        this.endPoint = '';
        this.httpStatus = '';
        this.errorMessage = '';
        this.vin = '';
        this.guid = '';
        this.dealerCode = '';
        this.brand = '';
        this.email = '';
        this.phone = '';
        this.portal = environment.X_BRAND;
        this.channel = (this.domainMatcher.getDomain === 'com' ? 'US' : 'CA') + "_" + environment.acm.X_CHANNEL_DEALER;
    };
    Object.defineProperty(LogService.prototype, "getRequestBody", {
        /**
         * @function getRequestBody returns the request body for log service
         * @returns {LogRequest} request body
         */
        get: function () {
            return {
                correlation_id: this.correlation_id,
                endPoint: this.endPoint,
                httpStatus: this.httpStatus,
                errorMessage: this.errorMessage,
                vin: this.vin,
                guid: this.guid,
                dealerCode: this.ngRedux.getState().authStore.dealerCode,
                channel: this.channel,
                portal: this.portal,
                brand: this.brand,
                email: this.email,
                phone: this.phone
            };
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @function setLogRequestParams sets the log request params
     * @param request request to be stored
     */
    LogService.prototype.setLogRequestParams = function (request) {
        Object.assign(this, request);
    };
    /**
     * @function setErrorMessage sets the error message of the log request
     * @param httpStatus http status
     * @param errorMessage error message from the api
     */
    LogService.prototype.setErrorMessage = function (httpStatus, errorMessage) {
        this.httpStatus = httpStatus;
        this.errorMessage = errorMessage;
    };
    /**
     * @function setRequest sets the request
     * @param type type of the api request made
     * @param body body of the request
     * @param headers headers of the request
     */
    LogService.prototype.setRequest = function (type, body, headers) {
        var brandCode = this.ngRedux.getState().vehicleStore.brandCode;
        this.brand = !!brandCode ? brandCode : '';
        switch (type) {
            case 'acm':
            case 'acm-dealer':
            case 'acm-cu':
            case 'customer_details':
                this.email = !!body && !!body['email'] ? body['email'] : !!headers ? headers.get('email') : '';
                this.phone = !!body && !!body['phoneNumber'] ? body['phoneNumber'] : !!headers ? headers.get('phone') : '';
                this.guid = !!headers ? headers.get('guid') : '';
                break;
            case 'addVehicle':
                this.vin = !!body && !!body['vehicle'] && !!body['vehicle'].vin ? body['vehicle'].vin : '';
                this.guid = this.ngRedux.getState().authStore.guid;
                break;
            case 'sub':
                this.vin = !!body && !!body['vin'] ? body['vin'] : '';
                this.guid = !!body && !!body['subscriberGuid'] ? body['subscriberGuid'] : '';
                break;
            case 'cvs_details':
            case 'preview':
            case 'vehicle_list':
                this.vin = !!headers ? headers.get('vin') : '';
                break;
            case 'vehicle_association':
                this.guid = !!headers ? headers.get('X-GUID') : '';
                break;
            case 'unlink':
                this.guid = !!body && !!body['guid'] ? body['guid'] : !!headers ? headers.get('x-guid') : '';
                this.vin = !!body && !!body['vin'] ? body['vin'] : '';
                break;
            default:
                break;
        }
    };
    /**
     * @function logRequest logs the reqeusts
     * @param err error from the api
     */
    LogService.prototype.logRequest = function (err) {
        this.setErrorMessage(err.status.toString(), JSON.stringify(err.error));
        this.apiCall().subscribe();
    };
    /**
     * @function apiCall api call to log request
     */
    LogService.prototype.apiCall = function () {
        var headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this.http.post("" + environment.apigw_url + environment.subscription.apis.logurl, Object.assign(this.getRequestBody), {
            headers: headers,
            params: new CustomHttpParams('log')
        });
    };
    LogService.ngInjectableDef = i0.defineInjectable({ factory: function LogService_Factory() { return new LogService(i0.inject(i1.NgRedux), i0.inject(i2.DomainMatcher), i0.inject(i3.HttpClient)); }, token: LogService, providedIn: "root" });
    return LogService;
}());
export { LogService };
