import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var TranslationService = /** @class */ (function () {
    /**
     * @constructor Establish the Inject value from angular core for the language
     * By default extablish the language to en(English)
     * Then attempt to set the language depending on the @param LOCALE_ID
     **/
    function TranslationService(locale_id, translate) {
        this.customLanguage = translate;
        this.customLanguage.setDefaultLang('en');
        this.customLanguage.use(locale_id);
    }
    /**
     * @function languageChange Return a event emmiter when the language change
     * @returns AObservable object that can be used to subscribe to the change of the language
     **/
    TranslationService.prototype.languageChange = function () {
        return this.customLanguage.onLangChange;
    };
    Object.defineProperty(TranslationService.prototype, "setLanguage", {
        /**
         * @function setLanguage Used to set the language for the app post the construction phase
         * @param {string} language Recive the name of the translation according to the json in the assets
         **/
        set: function (language) {
            this.customLanguage.use(language);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @function getCustomTranslation Used to adquired a desiredtranslation from the JSON language
     * @param {string | string[]} translation Posible values are string with the respective map for the JSON or
     * an array of strings with the respective map for the values of the JSON
     * @returns An Observable with the response of the desired translation to NGX SERVICE
     **/
    TranslationService.prototype.getCustomTranslation = function (translation) {
        return this.customLanguage.get(translation);
    };
    /**
     * @function getCurentLanguage Return the current language of the app
     * @returns A string with the value of the current language
     **/
    TranslationService.prototype.getCurrentLanguage = function () {
        return this.customLanguage.currentLang;
    };
    /*
    * Open Link / Smooth Scroll to the element
    * */
    TranslationService.prototype.linkAction = function (e) {
        e.preventDefault();
        var tarea = e.target.getAttribute('href');
        if (tarea.indexOf('http://') === 0 || tarea.indexOf('https://') === 0) {
            // do something here
            window.open(tarea, '_blank');
        }
        else {
            var element = document.getElementById(tarea.replace('#', ''));
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    TranslationService.ngInjectableDef = i0.defineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(i0.inject(i0.LOCALE_ID), i0.inject(i1.TranslateService)); }, token: TranslationService, providedIn: "root" });
    return TranslationService;
}());
export { TranslationService };
