import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { DomainMatcher } from '../services/matcher/domain-matcher';
/**
 * @export
 * @class FindvehicleGuardService
 * @implements {CanActivate}
 */
var GuidGuardService = /** @class */ (function () {
    function GuidGuardService(_router, reduxState, domainMatcher) {
        this._router = _router;
        this.reduxState = reduxState;
        this.domainMatcher = domainMatcher;
    }
    /**
     * @function canActivate will return true if guid exists
     * @returns {boolean}
     * @memberof GuidGuardService
     */
    GuidGuardService.prototype.canActivate = function () {
        var guidExists = !!this.reduxState.getState().authStore.guid;
        if (guidExists) {
            return true;
        }
        else {
            this._router.navigate(["/" + this.domainMatcher.getPortalState() + "/search", { outlets: { 'search_bar': null, 'content_bar': null } }], { skipLocationChange: true });
            return false;
        }
    };
    return GuidGuardService;
}());
export { GuidGuardService };
