import { NgRedux } from '@angular-redux/store';
import { rootReducer } from './reducers';
import { INITIAL_STATE } from './state.model';
var StoreModule = /** @class */ (function () {
    function StoreModule(store) {
        this.store = store;
        store.configureStore(rootReducer, INITIAL_STATE);
    }
    return StoreModule;
}());
export { StoreModule };
