import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
/**
  * @description This service is provided to fetch the domain and tell the components in wich portal are being loaded
  **/
var DomainMatcher = /** @class */ (function () {
    function DomainMatcher() {
        this.getDomainValue(window.location.host);
    }
    Object.defineProperty(DomainMatcher.prototype, "getDomain", {
        /**
        * @function getdomain Getter for the property domain
        * @returns {string} Value of the property domain
        **/
        get: function () {
            return this.domain;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DomainMatcher.prototype, "setDomain", {
        /**
        * @function setdomain Setter for the property domain
        * @returns {void}
        **/
        set: function (domain) {
            this.domain = domain;
        },
        enumerable: true,
        configurable: true
    });
    /**
    * @function getDomainValue Set value of the domain
    * @param {string} location URL of the environment
    * @returns {void}
    **/
    DomainMatcher.prototype.getDomainValue = function (location) {
        switch (location) {
            case environment.us_url:
                this.setDomain = 'com';
                break;
            case environment.ca_url:
                this.setDomain = 'ca';
                break;
            default:
                this.setDomain = 'com';
        }
    };
    /**
    * @function getPortalState returns the portal route
    * @returns {string} route
    **/
    DomainMatcher.prototype.getPortalState = function () {
        return window.location.href.indexOf('/us') > -1 ? 'us' : 'tci';
    };
    DomainMatcher.ngInjectableDef = i0.defineInjectable({ factory: function DomainMatcher_Factory() { return new DomainMatcher(); }, token: DomainMatcher, providedIn: "root" });
    return DomainMatcher;
}());
export { DomainMatcher };
