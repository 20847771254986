import { NgModule } from '@angular/core';
import {
  MatIconModule,
  MatDividerModule,
  MatInputModule,
  MatCardModule,
  MatListModule,
  MatCheckboxModule,
  MatButtonModule,
  MatSlideToggleModule
} from '@angular/material';

@NgModule({
  imports: [
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSlideToggleModule
  ],
  exports: [
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSlideToggleModule
  ]
})

export class CustomMaterialModule { }
