import { Component } from '@angular/core';
import { TranslationService } from '../../common/services/languages/translation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { select } from '@angular-redux/store';
import { AuthState } from '../../common/redux/auth-store/auth-state.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent {
  public isLoggedIn: boolean;
  /**
  *@property Property to observe the redux auth store
  **/
  @select('authStore') readonly authObservable: Observable<AuthState>;
  constructor(public translate: TranslationService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    this.authObservable.subscribe((authStore) => {
      this.isLoggedIn = authStore.isLoggedIn;
    });
  }
}
