import { Component } from '@angular/core';
import { TranslationService } from '../../common/services/languages/translation.service';
import { DomainMatcher } from '../../common/services/matcher/domain-matcher';
import { AgentContactService } from '../../common/services/agent-contact/agent-contact.service';
import { MatDialogRef } from '@angular/material';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  /**
  *@property property to show the welcome message
  **/
  public show: boolean;
  constructor(
    public dialogRef: MatDialogRef<WelcomeComponent>,
    public translate: TranslationService,
    public domainMatcher: DomainMatcher,
    public agentContactService: AgentContactService
  ) { }
  /**
   * @function getCaAgentContact returns the ca agent contact translation
   * @returns {string} the Spattern corresponding to the agent contact
   */
  public getCaAgentContact(): string {
    return this.agentContactService.getCaAgentContact();
  }
  /**
   * @function getUsAgentContact returns the us agent contact translation
   * @returns {string} the Spattern corresponding to the agent contact
   */
  public getUsAgentContact(region: string): string {
    return this.agentContactService.getUsAgentContact(region);
  }
  /**
   * @function getPhone returns the phone
   * @returns {string} return phone number
   */
  public getPhone(region: string): string {
    return this.agentContactService.getPhone(region);
  }
  /**
     * @method close closes the dialog box
    **/
  public close(): void {
    this.dialogRef.close();
  }
  public selectLanguage(event: boolean) {
    this.show = event;
  }
  /**
    * @function getPattern gets the translation pattern based on parameter - region based, portal brand based
    */
  public getPattern(join1: string, join2: string, parameter: 'region' | 'brand'): string {
    const joinParam = parameter === 'region' ? (this.domainMatcher.getDomain === 'ca' ? 'tci' : '') : environment.brand;
    return `${join1}${!!joinParam ? `-${joinParam}` : ''}${!!join2 ? `.${join2}` : ''}`;
  }
}
