import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import {map} from 'rxjs/operators';

export class MultiTranslateHttpLoader implements TranslateLoader {
  /**
   * @param http Instance of an HTTP instance
   * @param resources Array of objects with the desire languages assets
   **/
  constructor(
    private http: HttpClient,
    private resources: object[]
  ) {}

  /**
   * @function getTranslation is a implementation of the TranslateLoader class so
   * we ca be able to set more than one languge form different JSON's
   **/
  public getTranslation(lang: string): Observable<object> {
    const requests = this.resources.map(resource => {
      if (lang !== 'sp') {
        return this.http.get(`${resource['prefix']}${lang}${resource['suffix']}`);
      }

      if (lang === 'sp' && resource['suffix'] === '.json') {
        return this.http.get(`${resource['prefix']}${lang}${resource['suffix']}`);
      } else {
        return this.http.get(`${resource['prefix']}en${resource['suffix']}`);
      }
    });
    return forkJoin(requests).pipe(map(response => response.reduce((a, b) => Object.assign(a, b))));
  }
}
