var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import { environment } from '../../../environments/environment';
import { TranslationService } from '../../../common/services/languages/translation.service';
import { AuthenticationService } from '../../../dealers/authentication/services/authentication.service';
import { AuthDispatcher } from '../../../common/redux/auth-store/auth-dispatcher';
import { LogService } from '../../../common/services/log-service/log-service.service';
/** Creates custom http parameters */
var CustomHttpParams = /** @class */ (function (_super) {
    __extends(CustomHttpParams, _super);
    function CustomHttpParams(spec) {
        var _this = _super.call(this) || this;
        _this.spec = spec;
        return _this;
    }
    return CustomHttpParams;
}(HttpParams));
export { CustomHttpParams };
/** Handles the Api calls, data is passed to the api in {params:{key: value}} format*/
var SubscriptionApiHandlerService = /** @class */ (function (_super) {
    __extends(SubscriptionApiHandlerService, _super);
    function SubscriptionApiHandlerService(httpHandler, translate, authService, authDispatcher, logservice) {
        var _this = _super.call(this, httpHandler) || this;
        _this.httpHandler = httpHandler;
        _this.translate = translate;
        _this.authService = authService;
        _this.authDispatcher = authDispatcher;
        _this.logservice = logservice;
        _this.environment = environment.apigw_url;
        return _this;
    }
    /** This method returns the custom http params to defind the api request */
    SubscriptionApiHandlerService.prototype.getTypeParams = function (options) {
        return (options !== undefined && options.type !== undefined) ?
            new CustomHttpParams(options.type) : new CustomHttpParams('sub');
    };
    /** This method post a request to the passed url, body and options */
    // send the type: cvs in options to add custom cvs headers in header interceptors else subscription by default
    SubscriptionApiHandlerService.prototype.postRequest = function (api, body, options) {
        var headers = this.getHeaders(options);
        return _super.prototype.post.call(this, "" + this.environment + api, Object.assign(body), {
            headers: headers,
            params: this.getTypeParams(options)
        })
            .map(function (res) {
            return res;
        }).catch(this.handleError.bind(this));
    };
    /** This method get a request to the passed url, body and options */
    SubscriptionApiHandlerService.prototype.getRequest = function (api, options) {
        var headers = this.getHeaders(options);
        return _super.prototype.get.call(this, "" + this.environment + api, {
            headers: headers,
            params: this.getTypeParams(options)
        })
            .map(function (res) {
            return res;
        }).catch(this.handleError.bind(this));
    };
    /** This method puts a request to the passed url, body and options */
    SubscriptionApiHandlerService.prototype.putRequest = function (api, body, options) {
        var headers = this.getHeaders(options);
        return _super.prototype.put.call(this, "" + this.environment + api, Object.assign(body), {
            headers: headers,
            params: this.getTypeParams(options)
        })
            .map(function (res) {
            return res;
        }).catch(this.handleError.bind(this));
    };
    /** This method adds the custom headers taken from the user input to the http request */
    SubscriptionApiHandlerService.prototype.getHeaders = function (data) {
        this.authService.refreshToken('');
        var headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        if (data !== undefined && data.params !== undefined) {
            var params = data.params;
            switch (data.type) {
                case 'preview':
                    var asicode = params.asicode, hwType = params.hwType, generation = params.generation, region = params.region, brand = params.brand, vin = params.vin;
                    headers = headers.set('asi-code', !!asicode ? asicode : 'UNKNOWN');
                    headers = headers.set('hw-type', !!hwType ? hwType : 'UNKNOWN');
                    headers = headers.set('generation', !!generation ? generation : 'UNKNOWN');
                    headers = headers.set('region', !!region ? region : 'UNKNOWN');
                    headers = headers.set('X-BRAND', !!brand ? brand : 'UNKNOWN');
                    headers = headers.set('Language', this.getLanguage());
                    headers = headers.set('vin', !!vin ? vin : 'UNKNOWN');
                    break;
                case 'vehicle_association':
                    var guid = params.guid;
                    headers = !!guid ? headers.set('X-GUID', guid) : headers;
                    break;
                case 'vehicle_list':
                    headers = !!params.vin ? headers.set('VIN', params.vin) : headers;
                    headers = !!params.brand ? headers.set('x-brand', params.brand) : headers;
                    headers = !!params.generation ? headers.set('generation', params.generation) : headers;
                    break;
                case 'customer_details':
                    headers = (!!params.guid) ? headers.set('guid', params.guid) : headers;
                    break;
                case 'cvs_details':
                    headers = !!params.vin ? headers.set('vin', params.vin) : headers;
                    headers = headers.set('language', this.getLanguage());
                    headers = !!params.vin ? headers.set('vin', params.vin) : headers;
                    break;
                case 'unlink':
                    headers = !!params.guid ? headers.set('x-guid', params.guid) : headers;
                    break;
            }
        }
        return headers;
    };
    /** This method handles the errors of a http request*/
    SubscriptionApiHandlerService.prototype.handleError = function (err) {
        if (this.isAuthenticationError(err)) {
            return Observable.throw({ errorCode: 'UNAUTHORIZED' });
        }
        var errorCode = err.error && err.error.status && err.error.status.messages && err.error.status.messages.length &&
            err.error.status.messages.map(function (error) { return error.responseCode; });
        var throwObj = (!!errorCode && errorCode.length) ? errorCode : [err.status];
        var errorResponse = { 'success': false, errorCode: throwObj[0] };
        this.logservice.logRequest(err);
        return Observable.throw(errorResponse);
    };
    /** This method returns the language header for the  preview api*/
    SubscriptionApiHandlerService.prototype.getLanguage = function () {
        var lang = this.translate.getCurrentLanguage();
        return lang === 'en' ? 'en-US' : lang === 'fr' ? 'fr-CA' : 'es-PR';
    };
    /** This method determines if it is an authentication error from the api gateway*/
    SubscriptionApiHandlerService.prototype.isAuthenticationError = function (err) {
        if (this.checkErrorContent(err) && err.error.status.messages[0].detailedDescription.indexOf('expired') > 0) {
            this.authDispatcher.logout();
        }
        return (err.status === 0 && err.ok === false && err.statusText === 'Unknown Error') ||
            (err.status === 403 && this.checkErrorContent(err) && err.error.status.messages[0].responseCode === 'APIGW-403');
    };
    /** This checks the structure of the error if there is a status object*/
    SubscriptionApiHandlerService.prototype.checkErrorContent = function (err) {
        return err.error && err.error.status && err.error.status.messages && err.error.status.messages.length;
    };
    return SubscriptionApiHandlerService;
}(HttpClient));
export { SubscriptionApiHandlerService };
