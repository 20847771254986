import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import { AuthDispatcher } from '../../common/redux/auth-store/auth-dispatcher';
import { AppState } from '../../store/state.model';
import { TranslationService } from '../../common/services/languages/translation.service';
import { Subscription, Observable } from 'rxjs';
import { DomainMatcher } from '../../common/services/matcher/domain-matcher';
import { CustomerDispatcher } from '../../common/redux/customer/customer-dispatcher';
import { StepDispatcher } from '../../common/redux/steps-store/step-dispatcher';
import { AuthState } from '../../common/redux/auth-store/auth-state.model';
import { WelcomeComponent } from '../welcome/welcome.component';
import { environment } from '../../environments/environment';
import { RedirectService } from '../../common/services/redirect-service/redirect.service';

@Component({
  selector: 'app-header-dealer',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  /**
  *@property to store subscription
  **/
  private subscription: Subscription;
  /**
  *@property to store if logged in or not
  **/
  public isLoggedIn: boolean;
  /**
  *@property to store dealer name
  **/
  public dealerName: string;
  /**
  *@property Property to store the portal state tci or not
  **/
  public portalState: string;
  /**
  *@property Property to store login error - unauthorized
  **/
  public error: boolean;
  constructor(
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public ngRedux: NgRedux<AppState>,
    public authDispatcher: AuthDispatcher,
    public translate: TranslationService,
    public domainMatcher: DomainMatcher,
    public customerDispatcher: CustomerDispatcher,
    public stepDispatcher: StepDispatcher,
    public redirectService: RedirectService
  ) {

  }
  /**
   * @function OnInit init method
   * @returns {void}
   **/
  ngOnInit(): void {
  }
}
