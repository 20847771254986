import { OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { AuthDispatcher } from '../../common/redux/auth-store/auth-dispatcher';
import { TranslationService } from '../../common/services/languages/translation.service';
import { Subscription } from 'rxjs';
import { DomainMatcher } from '../../common/services/matcher/domain-matcher';
import { CustomerDispatcher } from '../../common/redux/customer/customer-dispatcher';
import { StepDispatcher } from '../../common/redux/steps-store/step-dispatcher';
import { RedirectService } from '../../common/services/redirect-service/redirect.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(route, datePipe, ngRedux, authDispatcher, translate, domainMatcher, customerDispatcher, stepDispatcher, redirectService) {
        this.route = route;
        this.datePipe = datePipe;
        this.ngRedux = ngRedux;
        this.authDispatcher = authDispatcher;
        this.translate = translate;
        this.domainMatcher = domainMatcher;
        this.customerDispatcher = customerDispatcher;
        this.stepDispatcher = stepDispatcher;
        this.redirectService = redirectService;
    }
    /**
     * @function OnInit init method
     * @returns {void}
     **/
    HeaderComponent.prototype.ngOnInit = function () {
    };
    return HeaderComponent;
}());
export { HeaderComponent };
