<mat-card class="download__instructions">
    <mat-card-title class="instructions__title">{{'confirmation.labels.app-download' | translate}}</mat-card-title>
    <mat-card-content class="instructions__steps">
      <div class="img__content">
        <span class="content__step">{{'confirmation.labels.step-1' | translate}}</span>
        <p class="step__description">{{'vehicleSummary.labels.appReference-description' | translate}}
          <a *ngIf="vehicle_brand == 'T'" class="vin-text">{{'vehicleSummary.labels.toyota-app' | translate}}</a>
          <a *ngIf="vehicle_brand !== 'T'" class="vin-text">{{'vehicleSummary.labels.lexus-app' | translate}}</a></p>
        <div class="qr__content">
          <div class="img__content">
            <span class="img__icon"><img src="qrImg/apple.png" alt="iOS:" /></span>
            <img *ngIf="vehicle_brand == 'T'" src="qrImg/toyota_iOS_QRCode.png" alt="Toyota App QR code" />
            <img *ngIf="vehicle_brand !== 'T'" src="qrImg/lexus_iOS_QRCode.png" alt="Lexus App QR code" />
          </div>
          <span class="title__void"></span>
          <div class="img__content">
            <span class="img__icon"><img src="qrImg/google-play.png" alt="Android:" /></span>
            <img *ngIf="vehicle_brand == 'T'" src="qrImg/toyota_Android_QRCode.png" alt="Toyota App QR code" />
            <img *ngIf="vehicle_brand !== 'T'" src="qrImg/lexus_Android_QRCode.png" alt="Lexus App QR code" />
          </div>
        </div>
      </div>
      <div class="steps__content">
        <div class="step__content">
        <div class="img__content">
        <span class="content__step">{{'confirmation.labels.step-2' | translate}}</span>
        <p class="step__description">{{'confirmation.labels.email-login' | translate}}</p>
        <div>
          <div class="img__content">
            <img *ngIf="vehicle_brand == 'T' && lang == 'en'" src="loginPageImg/toyota_en_login_page.png" alt="{{'confirmation.labels.login-page' | translate}}" />
            <img *ngIf="vehicle_brand == 'T' && lang == 'sp'" src="loginPageImg/toyota_sp_login_page.png" alt="{{'confirmation.labels.login-page' | translate}}" />
            <img *ngIf="vehicle_brand == 'T' && lang == 'fr'" src="loginPageImg/toyota_fr_login_page.png" alt="{{'confirmation.labels.login-page' | translate}}" />
            <img *ngIf="vehicle_brand !== 'T' && lang == 'en'" src="loginPageImg/lexus_en_register_page.png" alt="{{'confirmation.labels.login-page' | translate}}" />
            <img *ngIf="vehicle_brand !== 'T' && lang == 'sp'" src="loginPageImg/lexus_sp_login_page.png" alt="{{'confirmation.labels.login-page' | translate}}" />
            <img *ngIf="vehicle_brand !== 'T' && lang == 'fr'" src="loginPageImg/lexus_fr_login_page.png" alt="{{'confirmation.labels.login-page' | translate}}" />
          </div>
        </div>
        </div>
        <span class="img__void"></span>
        <div class="img__content">
          <span class="content__step">{{'confirmation.labels.step-3' | translate}}</span>
          <p class="step__description">{{'confirmation.labels.verify-account' | translate}}</p>
          <div>
            <div class="img__content">
                <img *ngIf="vehicle_brand == 'T' && lang == 'en'" src="activationPageImg/toyota_en_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
                <img *ngIf="vehicle_brand == 'T' && lang == 'sp'" src="activationPageImg/toyota_sp_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
                <img *ngIf="vehicle_brand == 'T' && lang == 'fr'" src="activationPageImg/toyota_fr_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
                <img *ngIf="vehicle_brand !== 'T' && lang == 'en'" src="activationPageImg/lexus_en_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
                <img *ngIf="vehicle_brand !== 'T' && lang == 'sp'" src="activationPageImg/lexus_sp_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
                <img *ngIf="vehicle_brand !== 'T' && lang == 'fr'" src="activationPageImg/lexus_fr_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="steps__content">
        <div class="step__content">
        <div class="img__content">
        <span class="content__step">{{'confirmation.labels.step-4' | translate}}</span>
        <p class="step__description">{{'confirmation.labels.add-vehicle' | translate}}</p>
        <div>
          <div class="img__content">
              <img *ngIf="vehicle_brand == 'T' && lang == 'en'" src="updatePswdPageImg/toyota_en_updatePswd_page.png" alt="{{'confirmation.labels.updatePswd-page' | translate}}" />
              <img *ngIf="vehicle_brand == 'T' && lang == 'sp'" src="updatePswdPageImg/toyota_sp_updatePswd_page.png" alt="{{'confirmation.labels.updatePswd-page' | translate}}" />
              <img *ngIf="vehicle_brand == 'T' && lang == 'fr'" src="updatePswdPageImg/toyota_fr_updatePswd_page.png" alt="{{'confirmation.labels.updatePswd-page' | translate}}" />
              <img *ngIf="vehicle_brand !== 'T' && lang == 'en'" src="updatePswdPageImg/lexus_en_addVehicle.png" alt="{{'confirmation.labels.updatePswd-page' | translate}}" />
              <img *ngIf="vehicle_brand !== 'T' && lang == 'sp'" src="updatePswdPageImg/lexus_sp_updatePswd_page.png" alt="{{'confirmation.labels.updatePswd-page' | translate}}" />
              <img *ngIf="vehicle_brand !== 'T' && lang == 'fr'" src="updatePswdPageImg/lexus_fr_updatePswd_page.png" alt="{{'confirmation.labels.updatePswd-page' | translate}}" />
          </div>
        </div>
        </div>
        <span class="img__void"></span>
        <div class="img__content">
          <span class="content__step">{{'confirmation.labels.step-5' | translate}}</span>
          <p class="step__description">{{'confirmation.labels.ready-exp' | translate}}</p>
          <div>
            <div class="img__content">
                <img *ngIf="vehicle_brand == 'T' && lang == 'en'" src="activationPageImg/toyota_en_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
                <img *ngIf="vehicle_brand == 'T' && lang == 'sp'" src="activationPageImg/toyota_sp_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
                <img *ngIf="vehicle_brand == 'T' && lang == 'fr'" src="activationPageImg/toyota_fr_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
                <img *ngIf="vehicle_brand !== 'T' && lang == 'en'" src="updatePswdPageImg/lexus_en_appImg.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
                <img *ngIf="vehicle_brand !== 'T' && lang == 'sp'" src="activationPageImg/lexus_sp_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
                <img *ngIf="vehicle_brand !== 'T' && lang == 'fr'" src="activationPageImg/lexus_fr_activation_page.png" alt="{{'confirmation.labels.verify-page' | translate}}" />
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="help__line img__content">
        <span>If you need further assistance, please contact the Lexus</span>
        <span>Guest Experience Center at <b>(800) 255-3987</b>. </span>
      </div>
    </mat-card-content>
  </mat-card>
