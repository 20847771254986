import { environment } from '../../../environments/environment';

/**
* @class State model declaration for store vehicle details
**/
export interface VehicleState {
  vin: string;
  brandCode: string;
  asiCode: string;
  imei: string;
  region: string;
  generation: string;
  nonCvtVehicle: boolean;
  stockPicReference: string;
  color: {
    colorCode: string;
    marketingColor: string;
  };
  dcm: {
    hardwareType: string;
  };
  capabilities: object[];
  model: {
    modelYear: string;
    modelCode: string;
    modelDescription: string;
  };
  preview: {
    subscriberGuid: string,
    subscriptions: object[],
    vehicleStatus: string,
  };
  subscriptions: {
    subscriptions: object[],
  };
  wifiStatus: string;
  waive: boolean;
  wifi: boolean;
  filteredCapabilities: string;
}
/**
* @class Initial State model for store vehicle details
**/
export const initialVehicleState: VehicleState = {
  brandCode: '',
  vin: '',
  asiCode: '',
  imei: null,
  region: '',
  generation: '',
  nonCvtVehicle: false,
  stockPicReference: '',
  color: {
    colorCode: '',
    marketingColor: '',
  },
  dcm: {
    hardwareType: '',
  },
  capabilities: [],
  model: { modelYear: '', modelCode: '', modelDescription: '' },
  preview: {
    subscriberGuid: null,
    subscriptions: [],
    vehicleStatus: null,
  },
  subscriptions: {
    subscriptions: []
  },
  wifiStatus: '',
  waive: false,
  wifi: false,
  filteredCapabilities: ''
};
