/**
  Angular core components declaration
**/
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

/**
  Dealer Portal Modules declaration
**/
import { PortalMaterialModule } from './material.components.module';
import { AppRoutingModule } from './app-routing.module';
import { ACMModule } from '../account-management/acm.module';
import { SubscriptionModule } from '../subscription/subscription.module';
import { LanguageModule } from '../common/services/languages/language.module';

/**
  Dealer Portal Components declaration
**/
import { AppRootComponent } from './main/main.component';
import { HeaderComponent } from './header/header.component';
import { BodyComponent } from './body/body.component';
import { DisplayMessageComponent } from '../common/components/display-message/display-message.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { LoginErrorComponent } from './login-error/login-error.component';
import { FooterComponent } from './footer/footer.component';
import { FaqsComponent } from '../common/components/faqs/faqs.component';
import { ReleaseNotesComponent } from '../common/components/release-notes/release-notes.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
/**
  Dealer Portal Redux Modules declaration
**/
import { NgReduxModule } from '@angular-redux/store';
import { DispatcherModule } from '../store/dispatcher.module';
import { StoreModule } from '../store/store.module';

/**
  Dealers service declaration
**/
import { TranslationService } from '../common/services/languages/translation.service';
import { SpinnerOverlayModule } from '../common/components/spinner-overlay.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BarecodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { GuidGuardService } from '../common/guardroutes/guidGuard.service';
import { VinGuardService } from '../common/guardroutes/vinGuard.service';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

@NgModule({
  entryComponents: [WelcomeComponent],
  declarations: [
    AppRootComponent,
    HeaderComponent,
    BodyComponent,
    DisplayMessageComponent,
    SearchBarComponent,
    WelcomeComponent,
    LoginErrorComponent,
    FooterComponent,
    FaqsComponent,
    ReleaseNotesComponent
  ],
  imports: [
    ACMModule,
    SubscriptionModule,
    SpinnerOverlayModule,
    BrowserAnimationsModule,
    PortalMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    DispatcherModule,
    StoreModule,
    LanguageModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    BarecodeScannerLivestreamModule
  ],
  providers: [
    TranslationService,
    GuidGuardService,
    VinGuardService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  bootstrap: [AppRootComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class Portal {
  constructor() { }
}
