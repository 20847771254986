import { tassign } from 'tassign';
import { SubscriptionState, initialSubscriptionState } from './create-subscription-state.model';
import { SubscriptionActions, Action } from './create-subscription-actions';

/**
* @function TokenReducer Will excecute the according triggered in the dispatcher
* @param state Store model
* @param action The action that will affect the store
* @return {tassing<SubscriptionState>} Value with the store
**/
export const SubscriptionReducer = function (state: SubscriptionState = initialSubscriptionState, action: Action): SubscriptionState {
  switch (action.type) {
    case SubscriptionActions.SET_ADD_VEHICLE_RES:
      return tassign(state, { addVehicle: action.payload.response });
    case SubscriptionActions.SET_SUB_RES:
      return action.payload.type === 'wifi' ?
        tassign(state, { wifiSubscription: action.payload.response }) :
        tassign(state, { packageSubscription: action.payload.response });
    case SubscriptionActions.SET_SUB_STATE:
      return tassign(state, {
        subscriptionComplete: action.payload.state
      });
    case SubscriptionActions.RESET_SUBSCRIPTIONSTORE:
      return initialSubscriptionState;
    default:
      return state;
  }
};


