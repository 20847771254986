import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
import { Vehicle } from '../../../subscription/models/vehicles/vehicle';
import { VehicleState } from './vehicle-state.model';
export type Action = FluxStandardAction<any, string>;

@Injectable()
export class VehicleActions {
  /**
  * @property Declaration of the action to set the x-brand
  **/
  static readonly SET_BRAND = 'SET_BRAND';
  /**
  * @property Declaration of the action to set the vin
  **/
  static readonly SET_VIN = 'SET_VIN';
  /**
  * @property Declaration of the action to set the vin
  **/
  static readonly SET_VEHICLE_DETAILS = 'SET_VEHICLE_DETAILS';
  /**
  * @property Declaration of the action to set the preview payload
  **/
  static readonly SET_PREVIEW_PAYLOAD = 'SET_PREVIEW_PAYLOAD';
  /**
  * @property Declaration of the action to set waive
  **/
  static readonly SET_WAIVE = 'SET_WAIVE';
  /**
  * @property Declaration of the action to set wifi
  **/
  static readonly SET_WIFI = 'SET_WIFI';
  /**
  * @property Declaration of the action to set wifi status
  **/
  static readonly SET_WIFI_STATUS = 'SET_WIFI_STATUS';
  /**
  * @property Declaration of the action to reset vehicle store
  **/
  static readonly RESET_VEHICLESTORE = 'RESET_VEHICLESTORE';
  /**
  * @property Declaration of the action to set filtered capabilities
  **/
  static readonly SET_FILTERED_CAPABILITIES = 'FILTERED_CAPABILITIES';
  /**
  * @property Declaration of the action to set filtered capabilities
  **/
  static readonly SET_TRANSLATIONS = 'SET_TRANSLATIONS';
  /**
  * @property Declaration of the action to set the active subscriptions
  **/
  static readonly SET_ACTIVE_SUBSCRIPTIONS = 'SET_ACTIVE_SUBSCRIPTIONS';

  /**
   * @function setBrand sets the brand of the vehicle
   * @param {string} brand Value of the brand (T or L)
   **/
  static setBrand(brand: string) {
    const x_brand = (brand === 'TOY' || brand === 'TOYOTA' || brand === 'T') ? 'T' : 'L';
    return {
      type: VehicleActions.SET_BRAND,
      payload: { brand: x_brand }
    };
  }

  /**
   * @function setVin sets the brand of the vehicle
   * @param {string} vin Value of the vin (T or L)
   **/
  static setVin(vin: string) {
    return {
      type: VehicleActions.SET_VIN,
      payload: { vin }
    };
  }

  /**
 * @function setVin sets the brand of the vehicle
 * @param {string} vin Value of the vin (T or L)
 **/
  static setVehicleDetails(vehicleDetails: Vehicle) {
    const x_brand = (vehicleDetails.brandCode === 'TOY' || vehicleDetails.brandCode === 'TOYOTA' || vehicleDetails.brandCode === 'T') ? 'T' : 'L';
    const storePayload = {
      vin: vehicleDetails.vin,
      brandCode: x_brand,
      asiCode: vehicleDetails.asiCode,
      imei: vehicleDetails.imei,
      region: vehicleDetails.region,
      generation: vehicleDetails.generation,
      nonCvtVehicle: vehicleDetails.nonCvtVehicle ? vehicleDetails.nonCvtVehicle :
        (
          !!vehicleDetails.imei &&
            ((vehicleDetails.generation !== 'PRE17CY' && !!vehicleDetails.asiCode) || (vehicleDetails.generation === 'PRE17CY'))
            ? false : true),
      stockPicReference: vehicleDetails.stockPicReference,
      color: {
        colorCode: vehicleDetails.color.colorCode,
        marketingColor: vehicleDetails.color.marketingColor
      },
      model: vehicleDetails.model,
      dcm: {
        hardwareType: vehicleDetails.dcm.hardwareType
      },
      capabilities: vehicleDetails.capabilities,
      wifi: vehicleDetails.capabilities.some(capability => (capability['name'] === 'Wifi' && capability['value'] === true))
    };

    return {
      type: VehicleActions.SET_VEHICLE_DETAILS,
      payload: storePayload
    };
  }

  /**
   * @function setPreviewPayload sets the preview payload of the vehicle
   * @param {string} preview contains product details
   **/
  static setPreviewPayload(preview: object) {
    return {
      type: VehicleActions.SET_VEHICLE_DETAILS,
      payload: { preview: { ...preview } }
    };
  }

  /**
   * @function setWaive sets the waive
   * @param {boolean} waive flag
   **/
  static setWaive(waive: boolean) {
    return {
      type: VehicleActions.SET_WAIVE,
      payload: { waive }
    };
  }

  /**
   * @function setWifi sets the wifi
   * @param {boolean} wifi flag
   **/
  static setWifi(wifi: boolean) {
    return {
      type: VehicleActions.SET_WIFI,
      payload: { wifi }
    };
  }
  /**
   * @function setWifiStatus sets the wifi status
   * @param {boolean} wifiStatus status of the wifi
   **/
  static setWifiStatus(wifiStatus: string) {
    return {
      type: VehicleActions.SET_WIFI_STATUS,
      payload: { wifiStatus }
    };
  }
  /**
   * @function resetVehicleStore sets the wifi
   **/
  static resetVehicleStore() {
    return {
      type: VehicleActions.RESET_VEHICLESTORE
    };
  }
  /**
   * @function setFilteredCapabilities sets the Filtered Capabilities
   * @param {string} filteredCapabilities capabilities of string type
   **/
  static setFilteredCapabilities(filteredCapabilities: string) {
    return {
      type: VehicleActions.SET_FILTERED_CAPABILITIES,
      payload: { filteredCapabilities }
    };
  }
  /**
   * @function setVehicleTranslations sets the Filtered Capabilities
   * @param {string} vehicle vehicle translations
   **/
  static setVehicleTranslations(vehicle: { model: object, color: object, capabilities: object }) {
    return {
      type: VehicleActions.SET_TRANSLATIONS,
      payload: vehicle
    };
  }
  /**
   * @function setActiveSubscriptions sets the active subscriptions of the vehicle
   * @param {string} subscriptions subscriptions of the vehicle
   **/
  static setActiveSubscriptions(subscriptions: object) {
    return {
      type: VehicleActions.SET_ACTIVE_SUBSCRIPTIONS,
      payload: { subscriptions: { ...subscriptions } }
    };
  }
}
