/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./body.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@angular-redux/store/lib/src/components/ng-redux";
import * as i7 from "../../common/redux/auth-store/auth-dispatcher";
import * as i8 from "../../common/services/languages/translation.service";
import * as i9 from "../../common/services/matcher/domain-matcher";
import * as i10 from "../../common/redux/customer/customer-dispatcher";
import * as i11 from "../../common/redux/steps-store/step-dispatcher";
import * as i12 from "../../common/services/redirect-service/redirect.service";
import * as i13 from "./body.component";
var styles_BodyComponent = [i0.styles];
var RenderType_BodyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BodyComponent, data: {} });
export { RenderType_BodyComponent as RenderType_BodyComponent };
export function View_BodyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-dealer", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.ActivatedRoute, i5.DatePipe, i6.NgRedux, i7.AuthDispatcher, i8.TranslationService, i9.DomainMatcher, i10.CustomerDispatcher, i11.StepDispatcher, i12.RedirectService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [["class", "search-bar "], ["name", "search_bar"]], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "search_bar"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "content-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [["name", "content_bar"]], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "content_bar"], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 7, 0); }, null); }
export function View_BodyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-body", [], null, null, null, View_BodyComponent_0, RenderType_BodyComponent)), i1.ɵdid(1, 49152, null, 0, i13.BodyComponent, [i8.TranslationService, i4.Router, i4.ActivatedRoute], null, null)], null, null); }
var BodyComponentNgFactory = i1.ɵccf("app-body", i13.BodyComponent, View_BodyComponent_Host_0, {}, {}, []);
export { BodyComponentNgFactory as BodyComponentNgFactory };
