<ng-container *ngTemplateOutlet="page_state==='welcome' ? welcome: default"></ng-container>

<ng-template #default>
  <mat-select class="selector" [(value)]="selectedLanguage" (valueChange)="changeLanguage()">
    <mat-option *ngFor="let language of selectorProperty" class="selector-option" value="{{language.value}}">
      {{language.description}}</mat-option>
  </mat-select>
</ng-template>

<ng-template #welcome>
  <mat-form-field class="form__field">
    <mat-label>{{'language.labels.welcome-placeholder' | translate}} </mat-label>
    <mat-select class="custom_black" [(value)]="selectedLanguage" (valueChange)="changeLanguage()">
      <mat-option *ngFor="let language of selectorProperty" value="{{language.value}}" (click)="languageSelected()">
        {{language.description}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
