var CustomerActions = /** @class */ (function () {
    function CustomerActions() {
    }
    CustomerActions.customerFound = function (flag) { return { type: CustomerActions.CUSTOMER_FOUND, payload: { value: flag } }; };
    CustomerActions.customerNotFoundError = function (flag) { return { type: CustomerActions.NOT_FOUND_ERROR, payload: { value: flag } }; };
    CustomerActions.setErrorCode = function (code) { return { type: CustomerActions.SET_ERROR_CODE, payload: { value: code } }; };
    CustomerActions.setSearchValue = function (searchValue) { return { type: CustomerActions.SET_SEARCH_VALUE, payload: { searchValue: searchValue } }; };
    CustomerActions.setCustomerDetails = function (first_name, last_name, email, phone) {
        return {
            type: CustomerActions.SET_CUSTOMER_DETAILS,
            payload: {
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone: phone
            }
        };
    };
    CustomerActions.setCustomerAddress = function (address) {
        return {
            type: CustomerActions.SET_CUSTOMER_ADDRESS,
            payload: {
                address: address
            }
        };
    };
    CustomerActions.setPrefferedLanguage = function (preferredLanguage) {
        return {
            type: CustomerActions.SET_PREFFERED_LANG,
            payload: {
                preferredLanguage: preferredLanguage
            }
        };
    };
    CustomerActions.setPrefLangApiCall = function (isCalled) {
        return {
            type: CustomerActions.PREF_LANG_API_CALL,
            payload: {
                prefferedLangApiCall: isCalled
            }
        };
    };
    CustomerActions.setCreateDate = function (createAccountTimestamp) {
        return {
            type: CustomerActions.SET_CREATE_DATE,
            payload: { createAccountTimestamp: createAccountTimestamp }
        };
    };
    CustomerActions.CUSTOMER_FOUND = 'CUSTOMER_FOUND';
    CustomerActions.NOT_FOUND_ERROR = 'NOT_FOUND_ERROR';
    CustomerActions.SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
    CustomerActions.SET_ERROR_CODE = 'SET_ERROR_CODE';
    CustomerActions.SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';
    CustomerActions.SET_CUSTOMER_ADDRESS = 'SET_CUSTOMER_ADDRESS';
    CustomerActions.SET_PREFFERED_LANG = 'SET_PREFFERED_LANG';
    CustomerActions.SET_CREATE_DATE = 'SET_CREATE_DATE';
    CustomerActions.PREF_LANG_API_CALL = 'PREF_LANG_API_CALL';
    return CustomerActions;
}());
export { CustomerActions };
