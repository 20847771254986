import { NgModule } from '@angular/core';
import {
  MatIconModule,
  MatDividerModule,
  MatInputModule,
  MatButtonModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatCardModule,
  MatTooltipModule,
  MatSelectModule,
  MatTableModule,
  MatSlideToggleModule,
} from '@angular/material';

@NgModule({
  imports: [
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    MatTooltipModule,
    MatSelectModule,
    MatTableModule,
    MatSlideToggleModule,
  ],
  exports: [
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    MatTooltipModule,
    MatSelectModule,
    MatTableModule,
    MatSlideToggleModule,
  ]
})

export class ACMMaterialModule { }
