<div *ngIf="!message" class="container">
  <div class="spinner-container">
    <mat-spinner class="spinner" color="primary" [diameter]=40 [strokeWidth]=6></mat-spinner>
  </div>
</div>

<div *ngIf="message" class="gif-container">
  <span class="container__title">{{message.value1}}</span>
  <span>{{message.value2}}</span>
  <span>{{message.value3}}</span>
</div>
