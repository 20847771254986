import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { environment } from '../../../environments/environment';
import { TranslationService } from '../../../common/services/languages/translation.service';
import { AuthenticationService } from '../../../dealers/authentication/services/authentication.service';
import { AuthDispatcher } from '../../../common/redux/auth-store/auth-dispatcher';
import { LogService } from '../../../common/services/log-service/log-service.service';

/** Creates custom http parameters */
export class CustomHttpParams extends HttpParams {
  constructor(public spec: string) {
    super();
  }
}

/** Handles the Api calls, data is passed to the api in {params:{key: value}} format*/
@Injectable()
export class SubscriptionApiHandlerService extends HttpClient {
  private environment: string;

  constructor(
    private httpHandler: HttpHandler,
    public translate: TranslationService,
    public authService: AuthenticationService,
    private authDispatcher: AuthDispatcher,
    public logservice: LogService
  ) {
    super(httpHandler);
    this.environment = environment.apigw_url;
  }

  /** This method returns the custom http params to defind the api request */
  getTypeParams(options: any) {
    return (options !== undefined && options.type !== undefined) ?
      new CustomHttpParams(options.type) : new CustomHttpParams('sub');
  }

  /** This method post a request to the passed url, body and options */
  // send the type: cvs in options to add custom cvs headers in header interceptors else subscription by default
  postRequest(api: string, body: any | null, options: any): Observable<any> {
    const headers: HttpHeaders = this.getHeaders(options);
    return super.post(`${this.environment}${api}`, Object.assign(body), {
      headers: headers,
      params: this.getTypeParams(options)
    })
      .map((res: any) => {
        return res;
      }).catch(this.handleError.bind(this));
  }

  /** This method get a request to the passed url, body and options */
  getRequest(api: string, options: any): Observable<any> {
    const headers: HttpHeaders = this.getHeaders(options);
    return super.get(`${this.environment}${api}`, {
      headers: headers,
      params: this.getTypeParams(options)
    })
      .map((res: any) => {
        return res;
      }).catch(this.handleError.bind(this));
  }
  /** This method puts a request to the passed url, body and options */
  putRequest(api: string, body: any | null, options: any): Observable<any> {
    const headers: HttpHeaders = this.getHeaders(options);
    return super.put(`${this.environment}${api}`, Object.assign(body), {
      headers: headers,
      params: this.getTypeParams(options)
    })
      .map((res: any) => {
        return res;
      }).catch(this.handleError.bind(this));
  }


  /** This method adds the custom headers taken from the user input to the http request */
  getHeaders(data: { type: string, params: any }): HttpHeaders {
    this.authService.refreshToken('');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    if (data !== undefined && data.params !== undefined) {
      const params = data.params;
      switch (data.type) {
        case 'preview':
          const { asicode, hwType, generation, region, brand, vin } = params;
          headers = headers.set('asi-code', !!asicode ? asicode : 'UNKNOWN');
          headers = headers.set('hw-type', !!hwType ? hwType : 'UNKNOWN');
          headers = headers.set('generation', !!generation ? generation : 'UNKNOWN');
          headers = headers.set('region', !!region ? region : 'UNKNOWN');
          headers = headers.set('X-BRAND', !!brand ? brand : 'UNKNOWN');
          headers = headers.set('Language', this.getLanguage());
          headers = headers.set('vin', !!vin ? vin : 'UNKNOWN');
          break;
        case 'vehicle_association':
          const { guid } = params;
          headers = !!guid ? headers.set('X-GUID', guid) : headers;
          break;
        case 'vehicle_list':
          headers = !!params.vin ? headers.set('VIN', params.vin) : headers;
          headers = !!params.brand ? headers.set('x-brand', params.brand) : headers;
          headers = !!params.generation ? headers.set('generation', params.generation) : headers;
          break;
        case 'customer_details':
          headers = (!!params.guid) ? headers.set('guid', params.guid) : headers;
          break;
        case 'cvs_details':
          headers = !!params.vin ? headers.set('vin', params.vin) : headers;
          headers = headers.set('language', this.getLanguage());
          headers = !!params.vin ? headers.set('vin', params.vin) : headers;
          break;
        case 'unlink':
          headers = !!params.guid ? headers.set('x-guid', params.guid) : headers;
          break;
      }
    }
    return headers;
  }

  /** This method handles the errors of a http request*/
  handleError(err: HttpErrorResponse): Observable<ArrayBuffer> {
    if (this.isAuthenticationError(err)) {
      return Observable.throw({ errorCode: 'UNAUTHORIZED' });
    } const errorCode = err.error && err.error.status && err.error.status.messages && err.error.status.messages.length &&
      err.error.status.messages.map(error => error.responseCode);
    const throwObj = (!!errorCode && errorCode.length) ? errorCode : [err.status];
    const errorResponse = { 'success': false, errorCode: throwObj[0] };
    this.logservice.logRequest(err);
    return Observable.throw(errorResponse);
  }

  /** This method returns the language header for the  preview api*/
  getLanguage(): string {
    const lang = this.translate.getCurrentLanguage();
    return lang === 'en' ? 'en-US' : lang === 'fr' ? 'fr-CA' : 'es-PR';
  }
  /** This method determines if it is an authentication error from the api gateway*/
  isAuthenticationError(err: HttpErrorResponse): boolean {
    if (this.checkErrorContent(err) && err.error.status.messages[0].detailedDescription.indexOf('expired') > 0) {
      this.authDispatcher.logout();
    }
    return (err.status === 0 && err.ok === false && err.statusText === 'Unknown Error') ||
      (err.status === 403 && this.checkErrorContent(err) && err.error.status.messages[0].responseCode === 'APIGW-403');
  }
  /** This checks the structure of the error if there is a status object*/
  checkErrorContent(err: HttpErrorResponse): boolean {
    return err.error && err.error.status && err.error.status.messages && err.error.status.messages.length;
  }
}

