import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslationService } from '../../common/services/languages/translation.service';
import { DomainMatcher } from '../../common/services/matcher/domain-matcher';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  /**
  *@property Used to set the default language on the selector
  **/
  public selectedLanguage: string;
  /**
   * @property selector values
   **/
  public selectorProperty: any;
  /**
   * @property the domain of the portal
   **/
  public getDomain: string;
  /**
   * @property determine if language selected
   **/
  @Output() langSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * @property show or not
   **/
  @Input() page_state: string;
  constructor(public translate: TranslationService,
    public domain: DomainMatcher) {
    this.selectedLanguage = 'en';
    this.getDomain = this.domain.getDomain;
    this.selectedLanguage = this.translate.getCurrentLanguage();
    this.selectorProperty = environment.acm[this.getDomain].languages;
  }
  /**
  *@method ngOnInit Starts the following operations:
  *- Get the current language set on the application
  **/
  ngOnInit() {
    this.translate.languageChange().subscribe((language: object) => {
      this.selectedLanguage = this.translate.getCurrentLanguage();
    });
  }
  /**
  *@function changeLanguage Set the language on the application
  **/
  public changeLanguage(): void {
    this.translate.setLanguage = this.selectedLanguage;
  }
  /**
  *@function languageSelected function call back after a option is selected
  **/
  public languageSelected(): void {
    this.langSelected.emit(true);
  }
}
