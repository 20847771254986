import { CustomerState, initialCustomerState } from './customer-state.model';
import { tassign } from 'tassign';
import { CustomerActions, Action } from './customer-actions';

export const CustomerReducer = function (state: CustomerState = initialCustomerState, action: Action): CustomerState {
  switch (action.type) {
    case CustomerActions.CUSTOMER_FOUND:
      return tassign(state, { isValid: action.payload.value });
    case CustomerActions.NOT_FOUND_ERROR:
      return tassign(state, { error_customer_not_found: action.payload.value });
    case CustomerActions.SET_ERROR_CODE:
      return tassign(state, { error_code: action.payload.value });
    case CustomerActions.SET_SEARCH_VALUE:
      return tassign(state, { searchValue: action.payload.searchValue });
    case CustomerActions.SET_CUSTOMER_DETAILS:
      return tassign(state, {
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        email: action.payload.email,
        phone: action.payload.phone,
      });
    case CustomerActions.SET_CUSTOMER_ADDRESS:
      return tassign(state, {
        address: action.payload.address
      });
    case CustomerActions.SET_PREFFERED_LANG:
      return tassign(state, {
        preferredLanguage: action.payload.preferredLanguage
      });
    case CustomerActions.SET_CREATE_DATE:
      return tassign(state, {
        createAccountTimestamp: action.payload.createAccountTimestamp
      });
    case CustomerActions.PREF_LANG_API_CALL:
      return tassign(state, {
        prefferedLangApiCall: action.payload.prefferedLangApiCall
      });
    default:
      return state;
  }
};

