import { Action, VehicleActions } from './vehicle-actions';
import { tassign } from 'tassign';
import { VehicleState, initialVehicleState } from './vehicle-state.model';

/**
* @function VehicleReducer Will excecute the according triggered in the dispatcher
* @param state Store model
* @param action The action that will affect the store
* @return {tassing<VehicleState>} Value with the store
**/
export const VehicleReducer = function (state: VehicleState = initialVehicleState, action: Action): VehicleState {
  switch (action.type) {
    case VehicleActions.SET_BRAND:
      return tassign(state, {
        brandCode: action.payload.brand
      });
    case VehicleActions.SET_VIN:
      return tassign(state, {
        vin: action.payload.vin
      });
    case VehicleActions.SET_WAIVE:
      return tassign(state, {
        waive: action.payload.waive
      });
    case VehicleActions.SET_WIFI:
      return tassign(state, {
        wifi: action.payload.wifi
      });
    case VehicleActions.SET_WIFI_STATUS:
      return tassign(state, {
        wifiStatus: action.payload.wifiStatus
      });
    case VehicleActions.SET_VEHICLE_DETAILS:
      return tassign(state, action.payload);
    case VehicleActions.SET_PREVIEW_PAYLOAD:
      return tassign(state, { preview: action.payload.preview });
    case VehicleActions.RESET_VEHICLESTORE:
      return initialVehicleState;
    case VehicleActions.SET_FILTERED_CAPABILITIES:
      return tassign(state, {
        filteredCapabilities: action.payload.filteredCapabilities
      });
    case VehicleActions.SET_TRANSLATIONS:
      return tassign(state, {
        model: action.payload.model,
        color: action.payload.color,
        capabilities: action.payload.capabilities
      });
    case VehicleActions.SET_ACTIVE_SUBSCRIPTIONS:
      return tassign(state, { subscriptions: action.payload.subscriptions });
    default:
      return state;
  }
};


