import { CustomHttpParams } from '../api-handler/api-handler.service';
import { environment } from '../../../environments/environment';
import { DomainMatcher } from '../../../common/services/matcher/domain-matcher';
import { Guid } from 'guid-typescript';
import { NgRedux } from '@angular-redux/store';
import { LogService } from '../../../common/services/log-service/log-service.service';
/** This method intercepts the http request and set the headers required by Account management*/
var HeaderInterceptorService = /** @class */ (function () {
    /**
    * @constructor has a dependency on domain matcher to generate a dynamic X-channel
    * @requires DomainMatcher
    **/
    function HeaderInterceptorService(domainMatcher, ngRedux, logService) {
        this.domainMatcher = domainMatcher;
        this.ngRedux = ngRedux;
        this.logService = logService;
    }
    /**
    * @event intercept Intercept any request that is triggered by APIHandler and sets the common header of the ORCH layer
    * @param domainMatcher
    * @returns {HTTPHandler} The response that is being intercepted so it can continue to the Network call
    **/
    HeaderInterceptorService.prototype.intercept = function (req, next) {
        var headers = {};
        if (req.params instanceof CustomHttpParams && req.params.spec) {
            switch (req.params.spec) {
                case 'acm':
                case 'acm-dealer':
                case 'acm-cu':
                    headers = this.getPortalHeaders(this.domainMatcher.getDomain, environment.acm.X_CHANNEL_DEALER, req.params.spec);
                    break;
            }
            this.logService.setDefaults();
            this.logService.setLogRequestParams({ correlation_id: headers['X-CORRELATIONID'], endPoint: req.url });
            this.logService.setRequest(req.params['spec'], req.body, req.headers);
        }
        return next.handle(req.clone({
            setHeaders: headers
        }));
    };
    /**
    * @function getPortalHeaders Return the common headers on the request
    * @param domain If calling from US or CA domain
    * @param channel Channel if it's a call from a DEALER/CUSTOMER/AGENT US or in CA
    * @returns {Object}
    **/
    HeaderInterceptorService.prototype.getPortalHeaders = function (domain, channel, type) {
        return {
            'x-correlationid': Guid.create().toString(),
            'X-Api-Key': environment.apigw_api_key,
            'Authorization': "Bearer " + this.ngRedux.getState().authStore.token,
            'X-CHANNEL': (domain === 'com' ? 'US' : 'CA') + "_" + channel,
            'X-BRAND': environment.X_BRAND
        };
    };
    return HeaderInterceptorService;
}());
export { HeaderInterceptorService };
