import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


/**
  * @description This service is provided to fetch the domain and tell the components in wich portal are being loaded
  **/
@Injectable({
  providedIn: 'root'
})

export class DomainMatcher {
  /**
  * @property {string} domain Value of the domain that is being loaded
  **/
  private domain: string;

  constructor() {
    this.getDomainValue(window.location.host);
  }

  /**
  * @function getdomain Getter for the property domain
  * @returns {string} Value of the property domain
  **/
  public get getDomain(): string {
    return this.domain;
  }

  /**
  * @function setdomain Setter for the property domain
  * @returns {void}
  **/
  private set setDomain(domain: string) {
    this.domain = domain;
  }

  /**
  * @function getDomainValue Set value of the domain
  * @param {string} location URL of the environment
  * @returns {void}
  **/
  private getDomainValue(location: string): void {
    switch (location) {
      case environment.us_url:
        this.setDomain = 'com';
        break;
      case environment.ca_url:
        this.setDomain = 'ca';
        break;
      default:
        this.setDomain = 'com';
    }
  }

  /**
  * @function getPortalState returns the portal route
  * @returns {string} route
  **/
  public getPortalState(): string {
    return window.location.href.indexOf('/us') > -1 ? 'us' : 'tci';
  }
}
