import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DomainMatcher } from '../matcher/domain-matcher';
import { CustomerDispatcher } from '../../redux/customer/customer-dispatcher';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(
    public router: Router,
    public domainMatcher: DomainMatcher,
    public customerDispatcher: CustomerDispatcher
  ) { }
  /**
   *@function backHome resets to home
   */
  public backHome(): void {
    this.router.navigate([`/${this.domainMatcher.getPortalState()}/search`],
      {
        queryParams: { reset: true, date: Date.now() },
        skipLocationChange: false
      });
  }
  /**
   *@function redirectError shows error component
   */
  public redirectError(errorCode: string): void {
    this.customerDispatcher.setErrorCode(errorCode);
    this.router.navigate([`/${this.domainMatcher.getPortalState()}/search`, { outlets: { 'search_bar': ['error'], 'content_bar': null } }],
      { skipLocationChange: true });
  }
  /**
   *@function redirect redirects based on the outlets and params provided
    * @param {object} search  search outlet - [value], '' or null
    * @param {object} content  content outlet - [value], '' or null
    * @param {object} params  query params
   */
  public redirect(search: object | '', content: object | '', params?): void {
    this.router.navigate([`/${this.domainMatcher.getPortalState()}/search`, {
      outlets: this.getOutlets(search, content)
    }],
      Object.assign({ skipLocationChange: true }, !!params ? { queryParams: params } : {}));
  }
  /**
   *@function getOutlets constructs the outlets based on param values
    * @param {object} search  search outlet - [value], '' or null
    * @param {object} content  content outlet - [value], '' or null
   */
  private getOutlets(search: object | '', content: object | ''): object {
    const outlet = {};
    Object.assign(outlet, !!search || search === null ? { 'search_bar': search } : {});
    Object.assign(outlet, !!content || content === null ? { 'content_bar': content } : {});
    return outlet;
  }
  /**
  * @function routeServerError routes to error page for unauthorized server error
  **/
  public routeServerError(): void {
    this.router.navigate(['/login-error'], { skipLocationChange: false });
  }
  /**
  * @function routeTci route tci
  **/
  public routeTci(): void {
    this.router.navigate([`/tci/search`]);
  }
  /**
  * @function routeEmpty route to default search
  **/
  public routeEmpty(skipLocation: boolean): void {
    this.router.navigate([`/${this.domainMatcher.getPortalState()}/search`], { skipLocationChange: skipLocation });

  }
}
