var AuthActions = /** @class */ (function () {
    function AuthActions() {
    }
    AuthActions.setDealerDetails = function (user, type) {
        return {
            type: AuthActions.SET_DEALER_DETAILS,
            payload: type === 'tci' ?
                {
                    email: '', name: user['given_name'], dealerCode: user['custom:DealerCode'], role: 'TCI_Enrolled',
                    UID: (!!user['identities'] && user['identities'].length > 0) ? user['identities'][0]['userId'] : user['custom:DealerCode']
                } :
                { email: user['email'], name: user['family_name'], dealerCode: user['dealer_number'], role: user['memberof'], UID: user['UID'] }
        };
    };
    /**
    * @function setGuid Will dispatch the action to store the GUID
    * @param {string} Guid Value of the GUID for the user account
    **/
    AuthActions.setGuid = function (guid) {
        return {
            type: AuthActions.SET_GUID,
            payload: { guid: guid }
        };
    };
    /**
    * @function setObjectId Will dispatch the action to store the objectId
    * @param {string} objectId Value of the objectId for the user account
    **/
    AuthActions.setObjectId = function (objectId) {
        return {
            type: AuthActions.SET_OBJECT_ID,
            payload: { objectId: objectId }
        };
    };
    /**
    * @function setLogin Will dispatch the action to store the azure login
    * @param {string} isLoggedIn boolean to store login
    **/
    AuthActions.setLogin = function (isLoggedIn) {
        return {
            type: AuthActions.SET_LOGIN,
            payload: { isLoggedIn: isLoggedIn }
        };
    };
    /**
    * @function setToken Will dispatch the action to store the GUID
    * @param {string} token Value of the GUID for the user account
    **/
    AuthActions.setToken = function (token) {
        return {
            type: AuthActions.SET_TOKEN,
            payload: { token: token }
        };
    };
    /**
    * @function logout logout
    * @return {void}
    **/
    AuthActions.logout = function () {
        return {
            type: AuthActions.SET_LOGIN,
            payload: { isLoggedIn: false }
        };
    };
    /**
    * @property Declaration of the action to store the Token
    **/
    AuthActions.SET_TOKEN = 'SET_TOKEN';
    /**
    * @property Declaration of the action to set the dealer details
    **/
    AuthActions.SET_DEALER_DETAILS = 'SET_DEALER_DETAILS';
    /**
    * @property Declaration of the action to get the Token
    **/
    AuthActions.FETCH_TOKEN = 'FETCH_TOKEN';
    /**
    * @property Declaration of the action to store the GUID
    **/
    AuthActions.SET_GUID = 'SET_GUID';
    /**
  * @property Declaration of the action to store the objectId
  **/
    AuthActions.SET_OBJECT_ID = 'SET_OBJECT_ID';
    /**
    * @property Declaration of the action to store the azure login
    **/
    AuthActions.SET_LOGIN = 'SET_LOGIN';
    return AuthActions;
}());
export { AuthActions };
