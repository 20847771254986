import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { DomainMatcher } from '../services/matcher/domain-matcher';
/**
 * @export
 * @class CreateSubscriptionGuardService
 * @implements {CanActivate}
 */
var VinGuardService = /** @class */ (function () {
    function VinGuardService(_router, reduxState, domainMatcher) {
        this._router = _router;
        this.reduxState = reduxState;
        this.domainMatcher = domainMatcher;
    }
    /**
     * @function canActivate will return true if both guid and vin exists
     * @returns {boolean}
     * @memberof createSubscriptionGuardService
     */
    VinGuardService.prototype.canActivate = function () {
        var isVinExists = !!this.reduxState.getState().vehicleStore.vin;
        if (isVinExists) {
            return true;
        }
        else {
            this._router.navigate(["/" + this.domainMatcher.getPortalState() + "/search", { outlets: { 'search_bar': null, 'content_bar': null } }], { skipLocationChange: true });
            return false;
        }
    };
    return VinGuardService;
}());
export { VinGuardService };
