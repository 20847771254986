import { Action, StepActions } from './step-actions';
import { tassign } from 'tassign';
import { StepState, initialStepState } from './step-state.model';

/**
* @function StepReducer Will excecute the according triggered in the dispatcher
* @param state Store model
* @param action The action that will affect the store
* @return {tassingn<StepState>} Value with the store
**/
export const StepReducer = function (state: StepState = initialStepState, action: Action): StepState {
  switch (action.type) {
    case StepActions.SET_NEXT_STEP:
      return tassign(state, {
        nextStep: action.payload.step
      });
    case StepActions.SET_ERROR_STATE:
      return tassign(state, {
        error: action.payload.error
      });
    case StepActions.IS_MOBILE:
      return tassign(state, {
        isMobile: action.payload.isMobile
      });
    case StepActions.STORE_GUID:
      return tassign(state, {
        storeGuid: action.payload.storeGuid
      });
    case StepActions.STORE_VIN:
      return tassign(state, {
        storeVin: action.payload.storeVin
      });
    case StepActions.SET_BANNER_MESSAGE:
      return tassign(state, {
        bannerMessage: action.payload.bannerMessage
      });
    default:
      return state;
  }
};

