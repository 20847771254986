import { Component, Injectable } from '@angular/core';
import { DispatcherModule } from '../../../store/dispatcher.module';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '../../../store/state.model';
import { StepActions } from './step-actions';

@Injectable({
  providedIn: DispatcherModule
})
export class StepDispatcher {
  constructor(
    private ngRedux: NgRedux<AppState>
  ) { }

  setNextStep(step: string) {
    this.ngRedux.dispatch(StepActions.setNext(step));
  }
  setError(error: string) {
    this.ngRedux.dispatch(StepActions.setError(error));
  }
  resetAll() {
    this.ngRedux.dispatch(StepActions.reset());
  }
  setIsMobile(value: boolean) {
    this.ngRedux.dispatch(StepActions.setIsMobile(value));
  }
  storeGuid(value: boolean) {
    this.ngRedux.dispatch(StepActions.storeGuid(value));
  }
  storeVin(value: boolean) {
    this.ngRedux.dispatch(StepActions.storeVin(value));
  }
  setBannerMessage(bannerMessage: object) {
    this.ngRedux.dispatch(StepActions.setBannerMessage(bannerMessage));
  }
}
