/**
* @class State model declaration for store vehicle details
**/
export interface SubscriptionState {
  wifiSubscription: object;
  packageSubscription: object;
  subscriptionComplete: boolean;
  addVehicle: object;
}
/**
* @class Initial State model for store vehicle details
**/
export const initialSubscriptionState: SubscriptionState = {
  packageSubscription: {},
  wifiSubscription: {},
  subscriptionComplete: false,
  addVehicle: {},
};
