import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
var MultiTranslateHttpLoader = /** @class */ (function () {
    /**
     * @param http Instance of an HTTP instance
     * @param resources Array of objects with the desire languages assets
     **/
    function MultiTranslateHttpLoader(http, resources) {
        this.http = http;
        this.resources = resources;
    }
    /**
     * @function getTranslation is a implementation of the TranslateLoader class so
     * we ca be able to set more than one languge form different JSON's
     **/
    MultiTranslateHttpLoader.prototype.getTranslation = function (lang) {
        var _this = this;
        var requests = this.resources.map(function (resource) {
            if (lang !== 'sp') {
                return _this.http.get("" + resource['prefix'] + lang + resource['suffix']);
            }
            if (lang === 'sp' && resource['suffix'] === '.json') {
                return _this.http.get("" + resource['prefix'] + lang + resource['suffix']);
            }
            else {
                return _this.http.get(resource['prefix'] + "en" + resource['suffix']);
            }
        });
        return forkJoin(requests).pipe(map(function (response) { return response.reduce(function (a, b) { return Object.assign(a, b); }); }));
    };
    return MultiTranslateHttpLoader;
}());
export { MultiTranslateHttpLoader };
