import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
export type Action = FluxStandardAction<any, string>;

@Injectable()
export class CustomerActions {
  static readonly CUSTOMER_FOUND = 'CUSTOMER_FOUND';
  static readonly NOT_FOUND_ERROR = 'NOT_FOUND_ERROR';
  static readonly SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
  static readonly SET_ERROR_CODE = 'SET_ERROR_CODE';
  static readonly SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';
  static readonly SET_CUSTOMER_ADDRESS = 'SET_CUSTOMER_ADDRESS';
  static readonly SET_PREFFERED_LANG = 'SET_PREFFERED_LANG';
  static readonly SET_CREATE_DATE = 'SET_CREATE_DATE';
  static readonly PREF_LANG_API_CALL = 'PREF_LANG_API_CALL';

  static customerFound(flag: boolean) { return { type: CustomerActions.CUSTOMER_FOUND, payload: { value: flag } }; }
  static customerNotFoundError(flag: boolean) { return { type: CustomerActions.NOT_FOUND_ERROR, payload: { value: flag } }; }
  static setErrorCode(code: string) { return { type: CustomerActions.SET_ERROR_CODE, payload: { value: code } }; }
  static setSearchValue(searchValue: object) { return { type: CustomerActions.SET_SEARCH_VALUE, payload: { searchValue } }; }
  static setCustomerDetails(first_name: string, last_name: string, email: string, phone: string) {
    return {
      type: CustomerActions.SET_CUSTOMER_DETAILS,
      payload: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone
      }
    };
  }
  static setCustomerAddress(address: object) {
    return {
      type: CustomerActions.SET_CUSTOMER_ADDRESS,
      payload: {
        address
      }
    };
  }
  static setPrefferedLanguage(preferredLanguage: string) {
    return {
      type: CustomerActions.SET_PREFFERED_LANG,
      payload: {
        preferredLanguage
      }
    };
  }
  static setPrefLangApiCall(isCalled: boolean) {
    return {
      type: CustomerActions.PREF_LANG_API_CALL,
      payload: {
        prefferedLangApiCall: isCalled
      }
    };
  }
  static setCreateDate(createAccountTimestamp: string) {
    return {
      type: CustomerActions.SET_CREATE_DATE,
      payload: { createAccountTimestamp }
    };
  }
}
