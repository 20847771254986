var StepActions = /** @class */ (function () {
    function StepActions() {
    }
    StepActions.setNext = function (step) {
        return {
            type: StepActions.SET_NEXT_STEP,
            payload: { step: step }
        };
    };
    StepActions.setError = function (error) {
        return {
            type: StepActions.SET_ERROR_STATE,
            payload: { error: error }
        };
    };
    StepActions.reset = function () {
        return {
            type: StepActions.RESET,
            payload: {}
        };
    };
    StepActions.setIsMobile = function (value) {
        return {
            type: StepActions.IS_MOBILE,
            payload: { isMobile: value }
        };
    };
    StepActions.storeGuid = function (value) {
        return {
            type: StepActions.STORE_GUID,
            payload: {
                storeGuid: value
            }
        };
    };
    StepActions.storeVin = function (value) {
        return {
            type: StepActions.STORE_VIN,
            payload: {
                storeVin: value
            }
        };
    };
    StepActions.setBannerMessage = function (bannerMessage) {
        return {
            type: StepActions.SET_BANNER_MESSAGE,
            payload: { bannerMessage: bannerMessage }
        };
    };
    StepActions.SET_NEXT_STEP = 'SET_NEXT_STEP';
    StepActions.SET_ERROR_STATE = 'SET_ERROR_STATE';
    StepActions.RESET = 'RESET';
    StepActions.IS_MOBILE = 'IS_MOBILE';
    StepActions.STORE_VIN = 'STORE_VIN';
    StepActions.STORE_GUID = 'STORE_GUID';
    StepActions.SET_BANNER_MESSAGE = 'SET_BANNER_MESSAGE';
    return StepActions;
}());
export { StepActions };
