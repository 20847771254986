import { environment } from '../../../environments/environment';
import { CustomHttpParams } from '../api-handler/api-handler.service';
import { NgRedux } from '@angular-redux/store';
import { Guid } from 'guid-typescript';
import { DomainMatcher } from '../../../common/services/matcher/domain-matcher';
import { LogService, CustomHttpParams as CustomHttpParam } from '../../../common/services/log-service/log-service.service';
var SubscriptionHeaderInterceptorService = /** @class */ (function () {
    function SubscriptionHeaderInterceptorService(ngRedux, domainMatcher, logService) {
        this.ngRedux = ngRedux;
        this.domainMatcher = domainMatcher;
        this.logService = logService;
    }
    /**
     * @event intercept Intercept any request that is triggered by APIHandler and sets the common header of the ORCH layer
     * @param domainMatcher
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>} The response that is being intercepted so it can continue to the Network call
     * @memberof SubscriptionHeaderInterceptorService
     **/
    SubscriptionHeaderInterceptorService.prototype.intercept = function (req, next) {
        var headers = {};
        var type = (req.params instanceof CustomHttpParams || req.params instanceof CustomHttpParam) ? req.params.spec : '';
        if (!!type) {
            switch (type) {
                case 'cvs_details':
                case 'customer_details':
                    headers = this.getHeaders(type, Guid.create().toString());
                    break;
                default:
                    headers = this.getCommonHeaders(type, this.domainMatcher.getDomain, Guid.create().toString());
            }
            this.logService.setDefaults();
            this.logService.setLogRequestParams({ correlation_id: headers['X-CORRELATIONID'], endPoint: req.url });
            this.logService.setRequest(req.params['spec'], req.body, req.headers);
        }
        return next.handle(req.clone({
            setHeaders: headers
        }));
    };
    /**
     * @function getHeaders Return the common headers on the request
     * @param {string} type
     * @param {string} correlationID
     * @returns {object}
     * @memberof SubscriptionHeaderInterceptorService
     **/
    SubscriptionHeaderInterceptorService.prototype.getHeaders = function (type, correlationID) {
        var obj = {
            'X-CORRELATIONID': correlationID
        };
        Object.assign(obj, type !== 'customer_details' ?
            {
                'Authorization': "Bearer " + this.ngRedux.getState().authStore.token,
                'X-Api-Key': environment.apigw_api_key,
                'X-CHANNEL': environment.subscription.X_CHANNEL_cvs
            }
            : this.getCustomerHeaders(this.domainMatcher.getDomain));
        return obj;
    };
    /**
     * @function getHeaders Return the common headers on the request
     * @param {string} domain
     * @returns {object}
     * @memberof SubscriptionHeaderInterceptorService
     */
    SubscriptionHeaderInterceptorService.prototype.getCustomerHeaders = function (domain) {
        return {
            'X-BRAND': environment.X_BRAND,
            'X-CHANNEL': (domain === 'com' ? 'US' : 'CA') + "_" + environment.acm.X_CHANNEL_DEALER,
            'X-Api-Key': environment.apigw_api_key,
            'Authorization': "Bearer " + this.ngRedux.getState().authStore.token,
        };
    };
    /**
     * @function getCommonHeaders Return the common headers on the request
     * @param {string} type
     * @param {string} domain
     * @param {string} correlationID
     * @param {string} api_key_sub
     * @returns {object}
     * @memberof SubscriptionHeaderInterceptorService
     */
    SubscriptionHeaderInterceptorService.prototype.getCommonHeaders = function (type, domain, correlationID) {
        var obj = {
            'X-CORRELATIONID': correlationID,
            'DATETIME': "" + new Date().getTime(),
            'X-CHANNEL': (domain === 'com' ? 'US' : 'CA') + "_" + environment.acm.X_CHANNEL_DEALER,
            'Authorization': "Bearer " + this.ngRedux.getState().authStore.token,
            'X-Api-Key': environment.apigw_api_key,
        };
        switch (type) {
            case 'addVehicle':
                Object.assign(obj, { 'GUID': this.ngRedux.getState().authStore.guid });
                break;
            case 'sub':
                Object.assign(obj, { 'DEALERCODE': !!this.ngRedux.getState().authStore.dealerCode ? this.ngRedux.getState().authStore.dealerCode : '' });
                break;
            case 'wifi':
            case 'log':
            case 'outage_banner':
                delete obj['DATETIME'];
                break;
        }
        Object.assign(obj, (type !== 'preview' && type !== 'wifi' && type !== 'log' && type !== 'outage_banner') ? { 'X-BRAND': this.ngRedux.getState().vehicleStore.brandCode } : {});
        return obj;
    };
    return SubscriptionHeaderInterceptorService;
}());
export { SubscriptionHeaderInterceptorService };
