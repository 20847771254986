import { AppState } from './state.model';
import { Reducer, combineReducers } from 'redux';
import { AuthReducer } from '../common/redux/auth-store/auth-reducer';
import { CustomerReducer } from '../common/redux/customer/customer-reducer';
import { VehicleReducer } from '../common/redux/vehicle-store/vehicle-reducer';
import { StepReducer } from '../common/redux/steps-store/step-reducer';
import { SubscriptionReducer } from '../subscription/common/redux/create-subscription/create-subscription-reducer';
import { initialStepState } from '../common/redux/steps-store/step-state.model';
import { initialVehicleState } from '../common/redux/vehicle-store/vehicle-state.model';
import { initialCustomerState } from '../common/redux/customer/customer-state.model';
import { tassign } from 'tassign';

const appReducer: Reducer<AppState> = combineReducers({
  authStore: AuthReducer,
  customerStore: CustomerReducer,
  vehicleStore: VehicleReducer,
  stepStore: StepReducer,
  subscriptionStore: SubscriptionReducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = {
      customerStore: initialCustomerState,
      vehicleStore: initialVehicleState,
      stepStore: initialStepState,
      authStore: tassign(state.authStore, { guid: '' })
    };
  }
  return appReducer(state, action);
};






