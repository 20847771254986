import { OnInit, EventEmitter } from '@angular/core';
import { TranslationService } from '../../common/services/languages/translation.service';
import { DomainMatcher } from '../../common/services/matcher/domain-matcher';
import { environment } from '../../environments/environment';
var LanguageSelectorComponent = /** @class */ (function () {
    function LanguageSelectorComponent(translate, domain) {
        this.translate = translate;
        this.domain = domain;
        /**
         * @property determine if language selected
         **/
        this.langSelected = new EventEmitter();
        this.selectedLanguage = 'en';
        this.getDomain = this.domain.getDomain;
        this.selectedLanguage = this.translate.getCurrentLanguage();
        this.selectorProperty = environment.acm[this.getDomain].languages;
    }
    /**
    *@method ngOnInit Starts the following operations:
    *- Get the current language set on the application
    **/
    LanguageSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translate.languageChange().subscribe(function (language) {
            _this.selectedLanguage = _this.translate.getCurrentLanguage();
        });
    };
    /**
    *@function changeLanguage Set the language on the application
    **/
    LanguageSelectorComponent.prototype.changeLanguage = function () {
        this.translate.setLanguage = this.selectedLanguage;
    };
    /**
    *@function languageSelected function call back after a option is selected
    **/
    LanguageSelectorComponent.prototype.languageSelected = function () {
        this.langSelected.emit(true);
    };
    return LanguageSelectorComponent;
}());
export { LanguageSelectorComponent };
