var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { TranslationService } from '../../common/services/languages/translation.service';
var DownloadAppComponent = /** @class */ (function () {
    function DownloadAppComponent(reduxState, translate) {
        this.reduxState = reduxState;
        this.translate = translate;
        var vehicleStore = this.reduxState.getState().vehicleStore;
        this.vehicleDetails = __assign({}, vehicleStore);
        this.vehicle_brand = 'L';
    }
    DownloadAppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.language = this.translate.getCurrentLanguage();
        this.translate.languageChange().subscribe(function (language) {
            _this.language = language['lang'];
        });
    };
    Object.defineProperty(DownloadAppComponent.prototype, "language", {
        /**
         * @function language method to set vehicle color and description property
         */
        set: function (lang) {
            this.lang = lang;
        },
        enumerable: true,
        configurable: true
    });
    return DownloadAppComponent;
}());
export { DownloadAppComponent };
