import { DomainMatcher } from '../matcher/domain-matcher';
import { environment } from '../../../environments/environment';
import { NgRedux } from '@angular-redux/store';
import * as i0 from "@angular/core";
import * as i1 from "../matcher/domain-matcher";
import * as i2 from "@angular-redux/store/lib/src/components/ng-redux";
var AgentContactService = /** @class */ (function () {
    function AgentContactService(domainMatcher, ngRedux) {
        this.domainMatcher = domainMatcher;
        this.ngRedux = ngRedux;
    }
    /**
   * @function getCaAgentContact returns the ca agent contact translation
   * @returns {string} the Spattern corresponding to the agent contact
   */
    AgentContactService.prototype.getCaAgentContact = function () {
        return environment.X_BRAND === 'T' ? 'welcome.post-message-toyota' : 'welcome.post-message-lexus';
    };
    /**
     * @function getUsAgentContact returns the us agent contact translation
     * @returns {string} the Spattern corresponding to the agent contact
     */
    AgentContactService.prototype.getUsAgentContact = function (region) {
        return environment.X_BRAND === 'T' ? "common-error.hint.toyota-" + region + "-agent-contact" : "common-error.hint.lexus-" + region + "-agent-contact";
    };
    /**
     * @function getAgentHint get agent hint
     * @returns {string} the pattern corresponding to the agent contact
     */
    AgentContactService.prototype.getAgentHint = function () {
        var hint;
        switch (this.ngRedux.getState().vehicleStore.region) {
            case 'CA':
                hint = this.getCaAgentContact();
                break;
            case 'HI':
                hint = this.getUsAgentContact('servco');
                break;
            case 'PR':
                hint = this.getUsAgentContact('tdpr');
                break;
            default:
                hint = this.getUsAgentContact('us');
                break;
        }
        return hint;
    };
    /**
     * @function getPhone returns the phone
     * @returns {string} return phone number
     */
    AgentContactService.prototype.getPhone = function (region) {
        return environment.subscription[region + "_agent_phone"];
    };
    AgentContactService.prototype.getPhoneHint = function () {
        var phone;
        switch (this.ngRedux.getState().vehicleStore.region) {
            case 'CA':
                phone = this.getPhone('tci');
                break;
            case 'HI':
                phone = this.getPhone('servco');
                break;
            case 'PR':
                phone = this.getPhone('tdpr');
                break;
            default:
                phone = this.getPhone('us');
                break;
        }
        return phone;
    };
    AgentContactService.ngInjectableDef = i0.defineInjectable({ factory: function AgentContactService_Factory() { return new AgentContactService(i0.inject(i1.DomainMatcher), i0.inject(i2.NgRedux)); }, token: AgentContactService, providedIn: "root" });
    return AgentContactService;
}());
export { AgentContactService };
