/**
  Angular core components declaration
**/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/**
  Legal Term Modules declaration
**/
import { LegalTermMaterialModule } from './material.components.module';
import { LanguageModule } from '../common/services/languages/language.module';

/**
 Legal Term Components declaration
**/
import { DisplayTermComponent } from './display-term/display-term.component';

/**
  Legal Term service declaration
**/
import { TranslationService } from '../common/services/languages/translation.service';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    DisplayTermComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LanguageModule,
    ServiceWorkerModule,
    LegalTermMaterialModule,
  ],
  exports: [
    DisplayTermComponent,
    LegalTermMaterialModule,
  ],
  providers: [
    TranslationService,
  ],
})

export class LegalTermModule {
  constructor() { }
}
