var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var SubscriptionActions = /** @class */ (function () {
    function SubscriptionActions() {
    }
    /**
      * @function setSubscriptionResponse sets the subscription result for packages and wifi
      * @param {object} result result of the api call
      **/
    SubscriptionActions.setSubscriptionResponse = function (result, type) {
        return {
            type: SubscriptionActions.SET_SUB_RES,
            payload: { response: __assign({}, result), type: type }
        };
    };
    /**
     * @function setAddVehicleResponse sets the subscription result for packages and wifi
     * @param {object} result result of the api call
     **/
    SubscriptionActions.setAddVehicleResponse = function (result) {
        return {
            type: SubscriptionActions.SET_ADD_VEHICLE_RES,
            payload: { response: __assign({}, result) }
        };
    };
    /**
     * @function setSubscriptionState sets the waive
     * @param {boolean} state flag
     **/
    SubscriptionActions.setSubscriptionState = function (state) {
        return {
            type: SubscriptionActions.SET_SUB_STATE,
            payload: { state: state }
        };
    };
    /**
     * @function resetSubscriptionStore sets the wifi
     **/
    SubscriptionActions.resetSubscriptionStore = function () {
        return {
            type: SubscriptionActions.RESET_SUBSCRIPTIONSTORE
        };
    };
    /**
    * @property Declaration of the action to set subscription of packages and wifi state
    **/
    SubscriptionActions.SET_SUB_STATE = 'SET_SUB_STATE';
    /**
    * @property Declaration of the action to set subscription of packages and wifi state
    **/
    SubscriptionActions.SET_SUB_RES = 'SET_SUB_RES';
    /**
    * @property Declaration of the action to set subscription of packages and wifi state
    **/
    SubscriptionActions.SET_ADD_VEHICLE_RES = 'SET_ADD_VEHICLE_RES';
    /**
    * @property Declaration of the action to set the skip package subscription
    **/
    SubscriptionActions.SKIP_PACKAGE_SUB = 'SKIP_PACKAGE_SUB';
    /**
    * @property Declaration of the action to RESET
    **/
    SubscriptionActions.RESET_SUBSCRIPTIONSTORE = 'RESET_SUBSCRIPTIONSTORE';
    return SubscriptionActions;
}());
export { SubscriptionActions };
