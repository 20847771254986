export const regex = {
  // tslint:disable-next-line:max-line-length
  passwordPattern: new RegExp(/^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&*\-_+=[\]{}|\\:',?\/`~\"();!]|\.(?!@)){8,16}$/),
  emailPattern: new RegExp('(^[-a-z0-9_]+(\.[-a-z0-9_]+)*@(?!yopmail|mailinator)([a-z0-9_][-a-z0-9_]*(\.(?!yopmail|mailinator)[-a-z0-9_]+)*\\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|law|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$)', 'i'),
  firstNamePattern: new RegExp('^(?=.*[a-zA-ZàâäèéêëîïôœùûüÿçáíóúñÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇÁÍÓÚÑ])([a-zA-Z- \'àâäèéêëîïôœùûüÿçáíóúñÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇÁÍÓÚÑ]{1,40})$'),
  precedingAndTrailingSpaces: new RegExp('^[^\\s]+(\\s+[^\\s]+)*$'),
  wordCount: new RegExp('^\\s*([a-zA-Z-\'àâäèéêëîïôœùûüÿçáíóúñÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇÁÍÓÚÑ]+\\s*){1,3}$'),
  lastNamePattern: new RegExp('^(?=.*[a-zA-ZàâäèéêëwîïôœùûüÿçáíóúñÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇÁÍÓÚÑ])([a-zA-Z- àâäèéêëîïôœùûüÿçáíóúñÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇÁÍÓÚÑ]{1,40})$'),
  phoneNumberPattern: new RegExp('^([1-9][0-9]{2})([0-9]{3})([0-9]{4})$|(^[\(]([1-9][0-9]{2})[\)]([0-9]{3})-([0-9]{4})$)'),
  phonePatternWithChar: new RegExp('(^[\(]([1-9][0-9]{2})[\)]([0-9]{3})-([0-9]{4})$)'),
  caps: new RegExp(/[A-Z]/, 'g'),
  small: new RegExp(/[a-z]/, 'g'),
  numbers: new RegExp(/[0-9]/, 'g'),
  special: new RegExp(/[^\sa-zA-Z0-9]/, 'g'),
  // tslint:disable-next-line:max-line-length
  emailAndPhone: new RegExp('(^[-a-z0-9_]+(\.[-a-z0-9_]+)*@(?!yopmail|mailinator)([a-z0-9_][-a-z0-9_]*(\.(?!yopmail|mailinator)[-a-z0-9_]+)*\\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|law|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$)|(^[1-9][0-9]{9}$)|(^[\(]([1-9][0-9]{2})[\)]([0-9]{3})-([0-9]{4})$)', 'i'),
  vinPattern: new RegExp('^[0-9A-HJ-NPR-Za-hj-npr-z]{17}$'),
  // tslint:disable-next-line:max-line-length
  emailPhoneVin: new RegExp('(^[-a-z0-9_]+(\.[-a-z0-9_]+)*@(?!yopmail|mailinator)([a-z0-9_][-a-z0-9_]*(\.(?!yopmail|mailinator)[-a-z0-9_]+)*\\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|law|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$)|(^[1-9][0-9]{9}$)|(^[\(]([1-9][0-9]{2})[\)]([0-9]{3})-([0-9]{4})$)|(^[0-9A-HJ-NPR-Za-hj-npr-z]{17}$)', 'i'),
  cityPattern: new RegExp('^[a-zA-Z0-9\\s\-\.\#\,\'\"\\\\/\;\:\~\?\!\^\*]{2,80}$'),
  zipCodeUSPattern: new RegExp('^\\d{5}$|^\\d{5}\\-\\d{4}$'),
  zipCodeCAPattern: new RegExp('^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]\\s\\s?[0-9][A-Z][0-9]$'),
  stateAndCountryPattern: new RegExp('^[a-zA-Z]{2}$'),
  addressPattern: new RegExp('^(?=.*[0-9a-zA-ZàâäèéêëîïôœùûüÿçáíóúñÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇÁÍÓÚÑ#])([0-9a-zA-Z- \'àâäèéêëîïôœùûüÿçáíóúñÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇÁÍÓÚÑ#]{3,256})$')
};
