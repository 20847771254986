var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { TranslationService } from '../../common/services/languages/translation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
var BodyComponent = /** @class */ (function () {
    function BodyComponent(translate, router, route) {
        var _this = this;
        this.translate = translate;
        this.router = router;
        this.route = route;
        this.authObservable.subscribe(function (authStore) {
            _this.isLoggedIn = authStore.isLoggedIn;
        });
    }
    __decorate([
        select('authStore'),
        __metadata("design:type", Observable)
    ], BodyComponent.prototype, "authObservable", void 0);
    return BodyComponent;
}());
export { BodyComponent };
