import { Routes } from '@angular/router';
import { BodyComponent } from './body/body.component';
import { DownloadAppComponent } from '../subscription/download-app/download-app.component';
var routes = [
    {
        path: '',
        component: BodyComponent,
        children: [
            {
                path: '',
                component: DownloadAppComponent,
                outlet: 'search_bar'
            }
        ]
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
