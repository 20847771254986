import { Component, Injectable } from '@angular/core';
import { DispatcherModule } from '../../../store/dispatcher.module';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '../../../store/state.model';
import { AuthActions } from './auth-actions';

@Injectable({
  providedIn: DispatcherModule,
})
export class AuthDispatcher {
  /**
  * @requires ngRedux Redux
  * @requires azureService MSAL azure library
  **/
  constructor(
    private ngRedux: NgRedux<AppState>
  ) { }

  /**
  * @function setDealerDetails Store the user credentials
  * @param dealer code
  * @param email User email
  * @param Name User prefered name
  * @return {void}
  **/
  setDealerDetails(user: object, type: string) {
    this.ngRedux.dispatch(AuthActions.setDealerDetails(user, type));
  }

  /**
  * @function setGuid Store the user GUID
  * @param guid User GUID
  * @return {void}
  **/
  setGuid(guid: string) {
    this.ngRedux.dispatch(AuthActions.setGuid(guid));
  }

  /**
  * @function setObjectId Store the user objectId
  * @param objectId User ObjectId
  * @return {void}
  **/
 setObjectId(objectId: string) {
  this.ngRedux.dispatch(AuthActions.setObjectId(objectId));
}
  /**
  * @function setLogin Store the user login
  * @param isLoggedIn boolean whether login
  * @return {void}
  **/
  setLogin(isLoggedIn: boolean) {
    this.ngRedux.dispatch(AuthActions.setLogin(isLoggedIn));
  }

  /**
  * @function setToken Store the token
  * @param token User token
  * @return {void}
  **/
  setToken(token: string) {
    this.ngRedux.dispatch(AuthActions.setToken(token));

  }
  /**
  * @function Logout logout
  * @return {void}
  **/
  logout() {
    this.ngRedux.dispatch(AuthActions.logout());
  }
}
