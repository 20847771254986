import { NgModule } from '@angular/core';
import { NgReduxModule, NgRedux } from '@angular-redux/store';
import { rootReducer } from './reducers';
import { AppState, INITIAL_STATE } from './state.model';

@NgModule({
  imports: [
    NgReduxModule,
  ]
})
export class StoreModule {
  constructor(
    public store: NgRedux<AppState>,
  ) {
    store.configureStore(
      rootReducer,
      INITIAL_STATE
    );
  }


}

