/**
* @class State model declaration for store flow steps
**/
export interface StepState {
  previousStep: string;
  nextStep: string;
  error: string;
  initStep: string;
  isMobile: boolean;
  storeVin: boolean;
  storeGuid: boolean;
  bannerMessage: object;
}
/**
* @class Initial State model for store Steps
**/
export const initialStepState: StepState = {
  previousStep: '',
  nextStep: '',
  error: '',
  initStep: '',
  isMobile: false,
  storeGuid: false,
  storeVin: false,
  bannerMessage: {}
};
