import { NgRedux } from '@angular-redux/store';
import { CustomerActions } from './customer-actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular-redux/store/lib/src/components/ng-redux";
import * as i2 from "../../../store/dispatcher.module";
var CustomerDispatcher = /** @class */ (function () {
    function CustomerDispatcher(ngRedux) {
        this.ngRedux = ngRedux;
    }
    /**
    * @function customerFound Indicates if the  customer is found (ignoring all the other errors)
    * @param flag true if customer found
    * @return {void}
    **/
    CustomerDispatcher.prototype.customerFound = function (flag) {
        this.ngRedux.dispatch(CustomerActions.customerFound(flag));
    };
    /**
    * @function customerNotFoundError Indicates if the response error is customer not found or not
    * @param flag true if error is customer not found
    * @return {void}
    **/
    CustomerDispatcher.prototype.customerNotFoundError = function (flag) {
        this.ngRedux.dispatch(CustomerActions.customerNotFoundError(flag));
    };
    /**
    * @function setErrorCode sets the error code in redux
    * @param code error code from the api
    * @return {void}
    **/
    CustomerDispatcher.prototype.setErrorCode = function (code) {
        this.ngRedux.dispatch(CustomerActions.setErrorCode(code));
    };
    /**
    * @function setSearchValue sets the search value of customer
    * @param searchValue search value {emai: ''} or {phone:''}
    * @return {void}
    **/
    CustomerDispatcher.prototype.setSearchValue = function (searchValue) {
        this.ngRedux.dispatch(CustomerActions.setSearchValue(searchValue));
    };
    /**
  * @function setCustomerDetails sets customer details
  * @param first_name first name of customer
  * @param last_name last name of customer
  * @param email email of customer
  * @param phone phone of customer
  * @return {void}
  **/
    CustomerDispatcher.prototype.setCustomerDetails = function (first_name, last_name, email, phone) {
        this.ngRedux.dispatch(CustomerActions.setCustomerDetails(first_name, last_name, email, phone));
    };
    /**
    * @function setCustomerAddress Indicates if the response error is customer not found or not
    * @param address address of customer
    * @return {void}
    **/
    CustomerDispatcher.prototype.setCustomerAddress = function (address) {
        this.ngRedux.dispatch(CustomerActions.setCustomerAddress(address));
    };
    /**
    * @function setPrefferedLanguage set prefered lang
    * @param preferredLanguage preferredLanguage of customer
    * @return {void}
    **/
    CustomerDispatcher.prototype.setPrefferedLanguage = function (preferredLanguage) {
        this.ngRedux.dispatch(CustomerActions.setPrefferedLanguage(preferredLanguage));
    };
    /**
    * @function setPrefLangApiCall set prefered lang
    * @param isCalled is the api called
    * @return {void}
    **/
    CustomerDispatcher.prototype.setPrefLangApiCall = function (isCalled) {
        this.ngRedux.dispatch(CustomerActions.setPrefLangApiCall(isCalled));
    };
    /**
    * @function setCreateDate set create date
    * @param createAccountTimestamp createAccountTimestamp of customer
    * @return {void}
    **/
    CustomerDispatcher.prototype.setCreateDate = function (createAccountTimestamp) {
        this.ngRedux.dispatch(CustomerActions.setCreateDate(createAccountTimestamp));
    };
    CustomerDispatcher.ngInjectableDef = i0.defineInjectable({ factory: function CustomerDispatcher_Factory() { return new CustomerDispatcher(i0.inject(i1.NgRedux)); }, token: CustomerDispatcher, providedIn: i2.DispatcherModule });
    return CustomerDispatcher;
}());
export { CustomerDispatcher };
