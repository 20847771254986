import { NgRedux } from '@angular-redux/store';
import { StepActions } from './step-actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular-redux/store/lib/src/components/ng-redux";
import * as i2 from "../../../store/dispatcher.module";
var StepDispatcher = /** @class */ (function () {
    function StepDispatcher(ngRedux) {
        this.ngRedux = ngRedux;
    }
    StepDispatcher.prototype.setNextStep = function (step) {
        this.ngRedux.dispatch(StepActions.setNext(step));
    };
    StepDispatcher.prototype.setError = function (error) {
        this.ngRedux.dispatch(StepActions.setError(error));
    };
    StepDispatcher.prototype.resetAll = function () {
        this.ngRedux.dispatch(StepActions.reset());
    };
    StepDispatcher.prototype.setIsMobile = function (value) {
        this.ngRedux.dispatch(StepActions.setIsMobile(value));
    };
    StepDispatcher.prototype.storeGuid = function (value) {
        this.ngRedux.dispatch(StepActions.storeGuid(value));
    };
    StepDispatcher.prototype.storeVin = function (value) {
        this.ngRedux.dispatch(StepActions.storeVin(value));
    };
    StepDispatcher.prototype.setBannerMessage = function (bannerMessage) {
        this.ngRedux.dispatch(StepActions.setBannerMessage(bannerMessage));
    };
    StepDispatcher.ngInjectableDef = i0.defineInjectable({ factory: function StepDispatcher_Factory() { return new StepDispatcher(i0.inject(i1.NgRedux)); }, token: StepDispatcher, providedIn: i2.DispatcherModule });
    return StepDispatcher;
}());
export { StepDispatcher };
