import { Injectable } from '@angular/core';
import { DispatcherModule } from '../../../store/dispatcher.module';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '../../../store/state.model';
import { CustomerActions } from './customer-actions';


@Injectable({
  providedIn: DispatcherModule,
})
export class CustomerDispatcher {

  constructor(
    private ngRedux: NgRedux<AppState>
  ) { }

  /**
  * @function customerFound Indicates if the  customer is found (ignoring all the other errors)
  * @param flag true if customer found
  * @return {void}
  **/
  customerFound(flag: boolean): void {
    this.ngRedux.dispatch(CustomerActions.customerFound(flag));
  }
  /**
  * @function customerNotFoundError Indicates if the response error is customer not found or not
  * @param flag true if error is customer not found
  * @return {void}
  **/
  customerNotFoundError(flag: boolean): void {
    this.ngRedux.dispatch(CustomerActions.customerNotFoundError(flag));
  }
  /**
  * @function setErrorCode sets the error code in redux
  * @param code error code from the api
  * @return {void}
  **/
  setErrorCode(code: string): void {
    this.ngRedux.dispatch(CustomerActions.setErrorCode(code));
  }
  /**
  * @function setSearchValue sets the search value of customer
  * @param searchValue search value {emai: ''} or {phone:''}
  * @return {void}
  **/
  setSearchValue(searchValue: object): void {
    this.ngRedux.dispatch(CustomerActions.setSearchValue(searchValue));
  }
  /**
* @function setCustomerDetails sets customer details
* @param first_name first name of customer
* @param last_name last name of customer
* @param email email of customer
* @param phone phone of customer
* @return {void}
**/
  setCustomerDetails(first_name: string, last_name: string, email: string, phone: string): void {
    this.ngRedux.dispatch(CustomerActions.setCustomerDetails(first_name, last_name, email, phone));
  }
  /**
  * @function setCustomerAddress Indicates if the response error is customer not found or not
  * @param address address of customer
  * @return {void}
  **/
  setCustomerAddress(address: object): void {
    this.ngRedux.dispatch(CustomerActions.setCustomerAddress(address));
  }
  /**
  * @function setPrefferedLanguage set prefered lang
  * @param preferredLanguage preferredLanguage of customer
  * @return {void}
  **/
  setPrefferedLanguage(preferredLanguage: string): void {
    this.ngRedux.dispatch(CustomerActions.setPrefferedLanguage(preferredLanguage));
  }
  /**
  * @function setPrefLangApiCall set prefered lang
  * @param isCalled is the api called
  * @return {void}
  **/
  setPrefLangApiCall(isCalled: boolean): void {
    this.ngRedux.dispatch(CustomerActions.setPrefLangApiCall(isCalled));
  }
  /**
  * @function setCreateDate set create date
  * @param createAccountTimestamp createAccountTimestamp of customer
  * @return {void}
  **/
  setCreateDate(createAccountTimestamp: string): void {
    this.ngRedux.dispatch(CustomerActions.setCreateDate(createAccountTimestamp));
  }
}
