import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
export type Action = FluxStandardAction<any, string>;

@Injectable()
export class StepActions {

  static readonly SET_NEXT_STEP = 'SET_NEXT_STEP';
  static readonly SET_ERROR_STATE = 'SET_ERROR_STATE';
  static readonly RESET = 'RESET';
  static readonly IS_MOBILE = 'IS_MOBILE';
  static readonly STORE_VIN = 'STORE_VIN';
  static readonly STORE_GUID = 'STORE_GUID';
  static readonly SET_BANNER_MESSAGE = 'SET_BANNER_MESSAGE';

  static setNext(step: string) {
    return {
      type: StepActions.SET_NEXT_STEP,
      payload: { step }
    };
  }
  static setError(error: string) {
    return {
      type: StepActions.SET_ERROR_STATE,
      payload: { error }
    };
  }
  static reset() {
    return {
      type: StepActions.RESET,
      payload: {}
    };
  }
  static setIsMobile(value: boolean) {
    return {
      type: StepActions.IS_MOBILE,
      payload: { isMobile: value }
    };
  }
  static storeGuid(value: boolean) {
    return {
      type: StepActions.STORE_GUID,
      payload: {
        storeGuid: value
      }
    };
  }
  static storeVin(value: boolean) {
    return {
      type: StepActions.STORE_VIN,
      payload: {
        storeVin: value
      }
    };
  }
  static setBannerMessage(bannerMessage: object) {
    return {
      type: StepActions.SET_BANNER_MESSAGE,
      payload: { bannerMessage }
    };
  }
}
