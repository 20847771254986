/**
 Angular core components declaration
 **/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/**
 ACM Modules declaration
 **/
import { ACMMaterialModule } from './material.components.module';
import { LanguageModule } from '../common/services/languages/language.module';

/**
 ACM Services declaration
 **/
import { HeaderInterceptorService } from './services/header-interceptor/header-interceptor.service';
import { TranslationService } from '../common/services/languages/translation.service';

/**
 ACM Components declaration
 **/
import { SignUpFormComponent } from './sign-up/sign-up-form.component';
import { CopyComponent } from './sign-up/partials/copy/copy.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { ResultCustomerComponent } from './result-customer/result-customer.component';
import { AddressComponent } from './address/address.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { PhonePipe } from '../common/pipes/phone.pipe';
import { PreferredLanguageComponent } from './preferred-language/preferred-language.component';

/**
 ACM Redux Modules declaration
 **/
import { DispatcherModule } from '../store/dispatcher.module';
import { LegalTermModule } from '../legal-term/legal-term.module';
import { PostalCodeDirective } from '../common/pipes/directives/postal-code.directive';

@NgModule({
  entryComponents: [
  ],
  declarations: [
    SignUpFormComponent,
    CopyComponent,
    LanguageSelectorComponent,
    AddressComponent,
    ResultCustomerComponent,
    CustomerDetailsComponent,
    PhonePipe,
    PreferredLanguageComponent,
    PostalCodeDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ACMMaterialModule,
    LanguageModule,
    DispatcherModule,
    LegalTermModule,
  ],
  exports: [
    SignUpFormComponent,
    LanguageSelectorComponent,
    CustomerDetailsComponent,
    ResultCustomerComponent,
    ACMMaterialModule,
    PreferredLanguageComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptorService, multi: true },
    TranslationService,
    PhonePipe
  ],
})

export class ACMModule {
  constructor() {
  }
}
