import { HttpClient } from '@angular/common/http';
import { MultiTranslateHttpLoader } from './multiple-loader/multiple-loader';
import { DomainMatcher } from '../matcher/domain-matcher';
import { environment } from '../../../environments/environment';
var link_base = environment;
var ɵ0 = (createTranslateLoader);
var LanguageModule = /** @class */ (function () {
    function LanguageModule() {
    }
    return LanguageModule;
}());
export { LanguageModule };
export function createTranslateLoader(http, domainService) {
    var brand = (link_base.brand === 'ty') ? 'toyota' : 'lexus';
    var resources = [
        { prefix: './assets/portal/i18n/', suffix: '.json' },
        { prefix: './assets/acm/i18n/', suffix: '.json' },
        { prefix: './assets/subscription/i18n/', suffix: '.json' }
    ];
    return new MultiTranslateHttpLoader(http, resources);
}
export { ɵ0 };
