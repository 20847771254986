import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
var AppRootComponent = /** @class */ (function () {
    function AppRootComponent(titleService, router) {
        this.titleService = titleService;
        this.router = router;
    }
    AppRootComponent.prototype.ngOnInit = function () {
        this.titleService.setTitle(environment.portalName + ' Dealer Portal');
    };
    /** This method redirects to the dashboard page*/
    AppRootComponent.prototype.redirect = function () {
        this.router.navigate(['/'], { queryParams: {}, skipLocationChange: false });
    };
    return AppRootComponent;
}());
export { AppRootComponent };
