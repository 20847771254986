import { Router } from '@angular/router';
import { DomainMatcher } from '../matcher/domain-matcher';
import { CustomerDispatcher } from '../../redux/customer/customer-dispatcher';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../matcher/domain-matcher";
import * as i3 from "../../redux/customer/customer-dispatcher";
var RedirectService = /** @class */ (function () {
    function RedirectService(router, domainMatcher, customerDispatcher) {
        this.router = router;
        this.domainMatcher = domainMatcher;
        this.customerDispatcher = customerDispatcher;
    }
    /**
     *@function backHome resets to home
     */
    RedirectService.prototype.backHome = function () {
        this.router.navigate(["/" + this.domainMatcher.getPortalState() + "/search"], {
            queryParams: { reset: true, date: Date.now() },
            skipLocationChange: false
        });
    };
    /**
     *@function redirectError shows error component
     */
    RedirectService.prototype.redirectError = function (errorCode) {
        this.customerDispatcher.setErrorCode(errorCode);
        this.router.navigate(["/" + this.domainMatcher.getPortalState() + "/search", { outlets: { 'search_bar': ['error'], 'content_bar': null } }], { skipLocationChange: true });
    };
    /**
     *@function redirect redirects based on the outlets and params provided
      * @param {object} search  search outlet - [value], '' or null
      * @param {object} content  content outlet - [value], '' or null
      * @param {object} params  query params
     */
    RedirectService.prototype.redirect = function (search, content, params) {
        this.router.navigate(["/" + this.domainMatcher.getPortalState() + "/search", {
                outlets: this.getOutlets(search, content)
            }], Object.assign({ skipLocationChange: true }, !!params ? { queryParams: params } : {}));
    };
    /**
     *@function getOutlets constructs the outlets based on param values
      * @param {object} search  search outlet - [value], '' or null
      * @param {object} content  content outlet - [value], '' or null
     */
    RedirectService.prototype.getOutlets = function (search, content) {
        var outlet = {};
        Object.assign(outlet, !!search || search === null ? { 'search_bar': search } : {});
        Object.assign(outlet, !!content || content === null ? { 'content_bar': content } : {});
        return outlet;
    };
    /**
    * @function routeServerError routes to error page for unauthorized server error
    **/
    RedirectService.prototype.routeServerError = function () {
        this.router.navigate(['/login-error'], { skipLocationChange: false });
    };
    /**
    * @function routeTci route tci
    **/
    RedirectService.prototype.routeTci = function () {
        this.router.navigate(["/tci/search"]);
    };
    /**
    * @function routeEmpty route to default search
    **/
    RedirectService.prototype.routeEmpty = function (skipLocation) {
        this.router.navigate(["/" + this.domainMatcher.getPortalState() + "/search"], { skipLocationChange: skipLocation });
    };
    RedirectService.ngInjectableDef = i0.defineInjectable({ factory: function RedirectService_Factory() { return new RedirectService(i0.inject(i1.Router), i0.inject(i2.DomainMatcher), i0.inject(i3.CustomerDispatcher)); }, token: RedirectService, providedIn: "root" });
    return RedirectService;
}());
export { RedirectService };
