import { Pipe, PipeTransform } from '@angular/core';
import { regex } from '../regex.constants';

@Pipe({
  name: 'phone-format'
})
/**
  * @function PhonePipe used to set the phone format
  * @param value it accepts a string
  * @returns string
  **/
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    return value.toString().match(regex.phonePatternWithChar) ? value : value.toString().replace(regex.phoneNumberPattern, '($1)$2-$3');
  }
}
