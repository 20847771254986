import { Injectable } from '@angular/core';
import { AppState } from '../../../store/state.model';
import { NgRedux } from '@angular-redux/store';
import { environment } from '../../../environments/environment';
import { DomainMatcher } from '../matcher/domain-matcher';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

/** Creates custom http calls get or post */
export class CustomHttpParams extends HttpParams {
  constructor(public spec: string) {
    super();
  }
}
@Injectable({
  providedIn: 'root'
})
export class LogService {
  /**
  *@property to store correlation_id
  **/
  public correlation_id: string;
  /**
  *@property to store endPoint
  **/
  public endPoint: string;
  /**
  *@property to store httpStatus
  **/
  public httpStatus: string;
  /**
  *@property to store errorMessage
  **/
  public errorMessage: string;
  /**
  *@property to store vin
  **/
  public vin: string;
  /**
  *@property to store guid
  **/
  public guid: string;
  /**
  *@property to store dealerCode
  **/
  public dealerCode: string;
  /**
  *@property to store channel
  **/
  public channel: string;
  /**
  *@property to store portal
  **/
  public portal: string;
  /**
  *@property to store brand
  **/
  public brand: string;
  /**
  *@property to store email
  **/
  public email: string;
  /**
  *@property to store phone
  **/
  public phone: string;
  constructor(
    public ngRedux: NgRedux<AppState>,
    public domainMatcher: DomainMatcher,
    private http: HttpClient
  ) {
    this.setDefaults();
  }
  /**
   * @function setDefaults sets defaults
   */
  public setDefaults(): void {
    this.correlation_id = '';
    this.endPoint = '';
    this.httpStatus = '';
    this.errorMessage = '';
    this.vin = '';
    this.guid = '';
    this.dealerCode = '';
    this.brand = '';
    this.email = '';
    this.phone = '';
    this.portal = environment.X_BRAND;
    this.channel = `${this.domainMatcher.getDomain === 'com' ? 'US' : 'CA'}_${environment.acm.X_CHANNEL_DEALER}`;
  }
  /**
   * @function getRequestBody returns the request body for log service
   * @returns {LogRequest} request body
   */
  private get getRequestBody(): LogRequest {
    return {
      correlation_id: this.correlation_id,
      endPoint: this.endPoint,
      httpStatus: this.httpStatus,
      errorMessage: this.errorMessage,
      vin: this.vin,
      guid: this.guid,
      dealerCode: this.ngRedux.getState().authStore.dealerCode,
      channel: this.channel,
      portal: this.portal,
      brand: this.brand,
      email: this.email,
      phone: this.phone
    };
  }
  /**
   * @function setLogRequestParams sets the log request params
   * @param request request to be stored
   */
  public setLogRequestParams(request: LogRequest): void {
    Object.assign(this, request);
  }
  /**
   * @function setErrorMessage sets the error message of the log request
   * @param httpStatus http status
   * @param errorMessage error message from the api
   */
  private setErrorMessage(httpStatus: string, errorMessage: string): void {
    this.httpStatus = httpStatus;
    this.errorMessage = errorMessage;
  }
  /**
   * @function setRequest sets the request
   * @param type type of the api request made
   * @param body body of the request
   * @param headers headers of the request
   */
  public setRequest(type: string, body: object, headers: HttpHeaders): void {
    const { vehicleStore: { brandCode } } = this.ngRedux.getState();
    this.brand = !!brandCode ? brandCode : '';
    switch (type) {
      case 'acm':
      case 'acm-dealer':
      case 'acm-cu':
      case 'customer_details':
        this.email = !!body && !!body['email'] ? body['email'] : !!headers ? headers.get('email') : '';
        this.phone = !!body && !!body['phoneNumber'] ? body['phoneNumber'] : !!headers ? headers.get('phone') : '';
        this.guid = !!headers ? headers.get('guid') : '';
        break;
      case 'addVehicle':
        this.vin = !!body && !!body['vehicle'] && !!body['vehicle'].vin ? body['vehicle'].vin : '';
        this.guid = this.ngRedux.getState().authStore.guid;
        break;
      case 'sub':
        this.vin = !!body && !!body['vin'] ? body['vin'] : '';
        this.guid = !!body && !!body['subscriberGuid'] ? body['subscriberGuid'] : '';
        break;
      case 'cvs_details':
      case 'preview':
      case 'vehicle_list':
        this.vin = !!headers ? headers.get('vin') : '';
        break;
      case 'vehicle_association':
        this.guid = !!headers ? headers.get('X-GUID') : '';
        break;
      case 'unlink':
        this.guid = !!body && !!body['guid'] ? body['guid'] : !!headers ? headers.get('x-guid') : '';
        this.vin = !!body && !!body['vin'] ? body['vin'] : '';
        break;
      default:
        break;
    }
  }
  /**
   * @function logRequest logs the reqeusts
   * @param err error from the api
   */
  public logRequest(err: HttpErrorResponse): void {
    this.setErrorMessage(err.status.toString(), JSON.stringify(err.error));
    this.apiCall().subscribe();
  }
  /**
   * @function apiCall api call to log request
   */
  private apiCall(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.post(`${environment.apigw_url}${environment.subscription.apis.logurl}`, Object.assign(this.getRequestBody), {
      headers: headers,
      params: new CustomHttpParams('log')
    });
  }
}
/**
* @class Log model declaration for store log request details
**/
export interface LogRequest {
  correlation_id?: string;
  endPoint?: string;
  httpStatus?: string;
  errorMessage?: string;
  vin?: string;
  guid?: string;
  dealerCode?: string;
  channel?: string;
  portal?: string;
  brand?: string;
  email?: string;
  phone?: string;
}
