import { Action, AuthActions } from './auth-actions';
import { tassign } from 'tassign';
import { AuthState, initialAuthState } from './auth-state.model';

/**
* @function AuthReducer Will excecute the according triggered in the dispatcher
* @param state Store model
* @param action The action that will affect the store
* @return {tassing<AuthState>} Value with the store
**/
export const AuthReducer = function (state: AuthState = initialAuthState, action: Action): AuthState {
  switch (action.type) {
    case AuthActions.SET_TOKEN:
      return tassign(state, {
        token: action.payload.token,
      });
    case AuthActions.SET_DEALER_DETAILS:
      return tassign(state, {
        email: action.payload.email,
        name: action.payload.name,
        dealerCode: action.payload.dealerCode,
        role: action.payload.role,
        UID: action.payload.UID
      });
    case AuthActions.SET_GUID:
      return tassign(state, {
        guid: action.payload.guid
      });
    case AuthActions.SET_OBJECT_ID:
      return tassign(state, {
        objectId: action.payload.objectId
      });
    case AuthActions.SET_LOGIN:
      return tassign(state, {
        isLoggedIn: action.payload.isLoggedIn
      });
    default:
      return state;
  }
};


