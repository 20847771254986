import { AuthState, initialAuthState } from '../common/redux/auth-store/auth-state.model';
import { CustomerState, initialCustomerState } from '../common/redux/customer/customer-state.model';
import { VehicleState, initialVehicleState } from '../common/redux/vehicle-store/vehicle-state.model';
import { StepState, initialStepState } from '../common/redux/steps-store/step-state.model';
import { initialSubscriptionState, SubscriptionState } from '../subscription/common/redux/create-subscription/create-subscription-state.model';

export interface AppState {
  authStore: AuthState;
  customerStore: CustomerState;
  vehicleStore: VehicleState;
  stepStore: StepState;
  subscriptionStore: SubscriptionState;
}
export const INITIAL_STATE = {
  authStore: initialAuthState,
  customerStore: initialCustomerState,
  vehicleStore: initialVehicleState,
  stepStore: initialStepState,
  subscriptionStore: initialSubscriptionState
};
