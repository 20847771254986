/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./spinner.component";
import * as i8 from "./overlay-ref.service";
import * as i9 from "./spinner-message.token";
var styles_FilePreviewOverlayComponent = [i0.styles];
var RenderType_FilePreviewOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilePreviewOverlayComponent, data: {} });
export { RenderType_FilePreviewOverlayComponent as RenderType_FilePreviewOverlayComponent };
function View_FilePreviewOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "spinner-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-spinner", [["class", "spinner mat-spinner mat-progress-spinner"], ["color", "primary"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(3, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"], strokeWidth: [2, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_3 = "primary"; var currVal_4 = 40; var currVal_5 = 6; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 3).diameter; var currVal_2 = i1.ɵnov(_v, 3).diameter; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
function View_FilePreviewOverlayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "gif-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "container__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message.value1; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message.value2; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.message.value3; _ck(_v, 6, 0, currVal_2); }); }
export function View_FilePreviewOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePreviewOverlayComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePreviewOverlayComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.message; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FilePreviewOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sppiner", [], null, null, null, View_FilePreviewOverlayComponent_0, RenderType_FilePreviewOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i7.FilePreviewOverlayComponent, [i8.SpinnerRef, i9.SPINNER_DATA], null, null)], null, null); }
var FilePreviewOverlayComponentNgFactory = i1.ɵccf("app-sppiner", i7.FilePreviewOverlayComponent, View_FilePreviewOverlayComponent_Host_0, {}, {}, []);
export { FilePreviewOverlayComponentNgFactory as FilePreviewOverlayComponentNgFactory };
