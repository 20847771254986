import { Component, OnInit } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '../../store/state.model';
import { TranslationService } from '../../common/services/languages/translation.service';


@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {

  /**
   *@property property to store Vehicle Object
   **/
  public vehicleDetails: object;
  /**
   * @property to store current langugae
   */
  public lang;
  /**
   * @property string that holds the vehicle brand
   */
  public vehicle_brand: string;
  constructor(
    public reduxState: NgRedux<AppState>,
    public translate: TranslationService
  ) {
    const { vehicleStore } = this.reduxState.getState();
    this.vehicleDetails = { ...vehicleStore };
    this.vehicle_brand = 'L';
  }

  ngOnInit(): void {
    this.language = this.translate.getCurrentLanguage();
    this.translate.languageChange().subscribe((language: object) => {
      this.language = language['lang'];
    });
  }

  /**
   * @function language method to set vehicle color and description property
   */
  public set language(lang) {
    this.lang = lang;
  }

}
