import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from './multiple-loader/multiple-loader';
import { DomainMatcher } from '../matcher/domain-matcher';
import { environment } from '../../../environments/environment';

const link_base = environment;

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, DomainMatcher]
      },
      isolate: true
    })
  ],
  exports: [TranslateModule],
})

export class LanguageModule {
  constructor() {
  }
}

export function createTranslateLoader(http: HttpClient, domainService: DomainMatcher) {
  const brand = (link_base.brand === 'ty') ? 'toyota' : 'lexus';
  const resources: object[] = [
    { prefix: './assets/portal/i18n/', suffix: '.json' },
    { prefix: './assets/acm/i18n/', suffix: '.json' },
    { prefix: './assets/subscription/i18n/', suffix: '.json' }
  ];
  return new MultiTranslateHttpLoader(http, resources);
}
